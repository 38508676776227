// (c: 0-1-2)
export function formatOrderName(toFormat: string): string {
  let thestring = toFormat.charAt(0).toUpperCase() + toFormat.slice(1).toLowerCase();
  thestring = thestring
                .toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase())
                 // eslint-disable-next-line no-useless-escape
                .replace(/\-[a-z]/g, match => match.toUpperCase())
                // eslint-disable-next-line no-useless-escape
                .replace('\([^)]*\))', '')
                .replace(' Hc', ' HC')
                .replace(' Tp', ' TP')
                .replace(' Dlx Ed', ' Delux Ed.')
                .replace(' Ptg', ' Printing')
                .replace(' Cvr', ' Cover')
                .replace(' Gn', ' GN')
                .replace(' (c: 0-1-2)', '')
                .replace(' (c: 1-0-0)', '')
                .replace(' (c: 1-1-2)', '')
                .replace(' (c: 1-1-2', '')
                .replace(' (c: 1-0-0', '')
                .replace(' (c: 1-0', '')
                .replace(' (c: 1-1', '')
                .replace(' (c: 1-', '')
                .replace(' (c: 1', '')
                .replace(' (c: 0-', '')
                .replace(' (c: 0', '')
                .replace(' (c:', '')
                .replace(' (c', '')
                .replace('(mr)', '')
                .replace('(net)', '')
                .replace('(res)', '')
                .replace(' Vol', ' vol'); 
  return thestring;
}

export function formatMainDescriptionComic(comic:IComic): string {
  return formatOrderName(comic.mainDescription.replace(comic.variantDescription, ''));
}

export function formatVariantDescriptionComic(comic:IComic): string {
  return formatOrderName(comic.variantDescription);
}

export function formatMainDescription(comicName:string): string {
  return formatOrderName(comicName);
}

export function formatVariantDescription(comicName:string): string {
  return formatOrderName(comicName);
}

export function formatCompanyName(toFormat: string): string {
  let thestring = toFormat.toLowerCase()
                    .replace(/(^\w|\s\w)/g, m => m.toUpperCase())
                    .replace('Dc', 'DC')
                    .replace('Prh', 'Comics')
                    .replace('Marvel', 'Marvel Comics')
                    .replace('Marvel Comics Comics', 'Marvel Comics')
                    .replace('Boom!', 'BOOM!')
                    .replace('IDW-prh', 'IDW Publishing')
                    .replace('Dynamite', 'Dynamite Entertainment')
                    .replace('Dynamite Entertainment Entertainment', 'Dynamite Entertainment')
                    .replace('Idw', 'IDW');
  return thestring;
}

export function formatPublisher(publisher:string): string {
  return formatCompanyName(publisher);
}
export function formatPublisherComic(comic:IComic): string {
  return formatPublisher(comic.publisher);
}

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export function formatReleaseMonth(month:number) {
  return (month ? monthNames[month-1] : '');
}
export function formatReleaseYear(year:number) {
  return (year < 25 ? '20' : year < 1000 ? '19' : '') + year;
}
export function formatReleaseDate(month:number, year:number): string {
  return formatReleaseMonth(month) + ' ' + formatReleaseYear(year);
}
export function formatReleaseDateComic(comic:IComic): string {
  return formatReleaseDate(comic.releaseMonth, comic.releaseYear);
}

export const getMonthAndYearFromDiamondId = (comicId:string) => {
  const month = comicId.substring(0,3);
  const year = parseInt(comicId.substring(3,5));

  const monthInt = month === 'JAN' ? 1 : 
                    month === 'FEB' ? 2 :
                    month === 'MAR' ? 3 :
                    month === 'APR' ? 4 :
                    month === 'MAY' ? 5 :
                    month === 'JUN' ? 6 :
                    month === 'JUL' ? 7 :
                    month === 'AUG' ? 8 :
                    month === 'SEP' ? 9 :
                    month === 'OCT' ? 10 :
                    month === 'NOV' ? 11 :
                    month === 'DEC' ? 12 : 999;

  if (monthInt === 999) {
    console.log('unrecognised month: ' + month + '  ' + comicId);
    return {month:0, year: 0};
  }

  return {month:monthInt, year: year};
}

export const getYearFromTitle = (variantDescription:string) => {
  if (variantDescription.indexOf('(') > -1 && variantDescription.indexOf(')') > -1) {
    const year = parseInt(variantDescription.substring(variantDescription.indexOf('(')+1,variantDescription.indexOf(')')));
    console.log('year: ' + year);
    return year;
  }
  return 0;
}