import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { addStock } from '../../API';
import { blankComic, copyComic, ItemType, PeriodType } from '../../common';
import AdminNavigation from '../admin/AdminNavigation'
import AddPreOrderItem from './AddPreOrderItem';
import AddAccessoryItem from './AddAccessoryItem';
import AddBackIssueItem from './AddBackIssueItem';
import AddGameItem from './AddGameItem';
import AddNewStockItem from './AddNewStockItem';
import { handleImageDrop } from './ViewEditStockItemSwitcher';
import { useLocation } from 'react-router-dom';

type Props = {
  itemType: ItemType;
  periodType?: PeriodType;
  batches: IBatch[],
}

const AdminStockItemAdd: React.FC<Props> = ({ itemType, periodType, batches }) => {
  
  const location = useLocation();
  const [formComic, setFormComic] = useState<IComic>(
    (location != null && location.state != null && location.state.copyComic != null) ?
    copyComic(location.state.copyComic, itemType, periodType) : blankComic(itemType, periodType)
  );

  // drag and drop stuff
  const [drag, setDrag] = React.useState(false);
  let dropRef = React.useRef<HTMLDivElement>(null);
  let dragCounter = 0;

  React.useEffect(() => {
    let div:any = dropRef.current;
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);
    return () => {
      div.removeEventListener('dragenter', handleDragIn);
      div.removeEventListener('dragleave', handleDragOut);
      div.removeEventListener('dragover', handleDrag);
      div.removeEventListener('drop', handleDrop);
    };
  });
  
  const handleDrag = (e: { preventDefault: () => void; stopPropagation: () => void; }) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: { preventDefault: () => void; stopPropagation: () => void; dataTransfer: { items: string | any[]; }; }) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) setDrag(true);
  };

  const handleDragOut = (e: { preventDefault: () => void; stopPropagation: () => void; }) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter--;
    if (dragCounter === 0) setDrag(false);
  };

  const handleDrop = (e: { preventDefault: () => void; stopPropagation: () => void; dataTransfer: { files: { name: any; }[]; clearData: () => void; }; }) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleImageDrop(e, setFormComic, formComic)
      dragCounter = 0;
    }
  };

  // end drag and drop stuff

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    // TODO error handling doesn't work
    addStock(formComic)
      .then(({ status }) => {
        console.log('status = ' + status);
        if (status !== 201) {
          Swal.fire("Fail", "Fail", "error");
        }
        else {
          Swal.fire("Success", "Stock added", "success").then(() => {

            const newFormComic = {
              ...formComic,
              comicId: '',
              images: []
            }
            setFormComic(newFormComic);
          });
        }
      }).catch((err) => {console.log('error ' + err); Swal.fire("Fail", "Fail", "error");}).finally(() => {console.log('finally');});
  }

  const handleCancel = () => {
    setFormComic(blankComic(itemType, periodType));
  }

  return (
    <>
      <article ref={dropRef} className={drag ? 'filedrop drag' : 'filedrop ready'}>
        <div className="single-add-item">
        { itemType === ItemType.COMIC && periodType === PeriodType.NEW ?
          (<AddNewStockItem formComic={formComic} setFormComic={setFormComic} batches={batches} handleCancel={handleCancel} handleFormSubmit={handleFormSubmit} />) :
          itemType === ItemType.GAME ?
            (<AddGameItem formComic={formComic} setFormComic={setFormComic} batches={batches} handleCancel={handleCancel} handleFormSubmit={handleFormSubmit} />) :
            itemType === ItemType.ACCESSORY ?
              (<AddAccessoryItem formComic={formComic} setFormComic={setFormComic} batches={batches} handleCancel={handleCancel} handleFormSubmit={handleFormSubmit} />) :
              itemType === ItemType.COMIC && periodType === PeriodType.PRE_ORDER ?
                (<AddPreOrderItem formComic={formComic} setFormComic={setFormComic} batches={batches} handleCancel={handleCancel} handleFormSubmit={handleFormSubmit} />) :
                (<AddBackIssueItem formComic={formComic} setFormComic={setFormComic} batches={batches} handleCancel={handleCancel} handleFormSubmit={handleFormSubmit} />)}
        </div>
      </article>
      <nav><AdminNavigation /></nav>
      <aside></aside>
    </>
  )
}

export default AdminStockItemAdd