import React, { useState } from 'react'
import { formatEbayPriceComic, formatMoney, formatPriceComic, formatPriceOverrideComicRaw, formatPriceSRPRaw } from '../../utils/MoneyFormats'
import './ViewEditStockItemSwitcher.scss';
import EbayControls from '../order/EbayControls'
import { getImage } from '../../utils/ImageHandler'
import { Link } from 'react-router-dom';
import { getItemTypeName, getPeriodTypeName, getSupplierTypeName, ItemType, PeriodType, SupplierType } from '../../common';

type Props = {
  formComic: IComic,
  setFormComic: (formComic: IComic) => void,
  deleteStock: (formComic: IComic) => void,
  addStock?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, comic: IComic) => void,
  handleEditMode: () => void,
}

const ViewGenericItem: React.FC<Props> = ({ formComic, setFormComic, deleteStock, addStock, handleEditMode }) => {

  const [result, setResult] = useState('');

  return (
    <div>
      <div>
        {formComic !== undefined && (formComic.hasImage === undefined || formComic.hasImage) ?
          (<div className="comic-item-preview-holder admin-preview right"><img className='comic-item-preview-cover' alt='cover' src={getImage(formComic)} /></div>)
          : <div className='comic-item-preview-no-image right' />}

        <h1 className='inline no-top-margin'>{formComic.mainDescription}</h1>{formComic.variantDescription !== '' ? (<>&nbsp;&nbsp;•&nbsp;&nbsp;</>) : ''}
        <h2 className='inline no-top-margin'>{formComic.variantDescription}</h2><br />
        {formComic.ebaySku ? (<div>{formComic.ebaySku} - <a href={'https://www.ebay.com.au/itm/' + formComic.ebayListingId} target="_blank" rel="noreferrer">{formComic.ebayListingId}</a></div>) : ''}
        <span className='inline no-top-margin'>{formComic.comicId} -- {formComic.releaseMonth}/{formComic.releaseYear} -- {formComic.publisher}</span><br />
        <span className='inline no-top-margin'>{formComic.batch?.name} -- {formComic.batchDate} -- {formComic.type}</span>
        <span className='inline no-top-margin'>{formComic.selected ? 'true' : 'false'} -- {formComic.writer} -- {formComic.artist} -- {formComic.coverArtist}</span>
        <div>{getItemTypeName(formComic.itemType as ItemType)}&nbsp;•&nbsp;{getPeriodTypeName(formComic.periodType as PeriodType)}&nbsp;•&nbsp;{getSupplierTypeName(formComic.supplierType as SupplierType)}&nbsp;•&nbsp;({formComic.type})</div>
        <div>{formComic.images.length > 0 ? formComic.images.length + 'x images (' + JSON.stringify(formComic.images) + ')' : ''}</div>
        <h1>
          x{formComic.number} (of {formComic.initialQuantity}) &nbsp;&nbsp;&nbsp;&nbsp;{formComic.priceOverride ?
            (<><span className='strikethrough'>${formatPriceSRPRaw(formComic.srp)}</span> • ${formatPriceOverrideComicRaw(formComic)} (${formatEbayPriceComic(formComic, formComic.batch.discountPercent)})</>) :
            (<span>${formatPriceComic(formComic, formComic.batch.discountPercent)} (${formatEbayPriceComic(formComic, formComic.batch.discountPercent)})</span>)}
          {/* {formComic.number != formComic.initialQuantity ? '(of '+formComic.initialQuantity+')' : ''} */}
        </h1>
        <h4>
          Sold: {formComic.salesQuantity} at ${formatMoney(formComic.salesValue / formComic.salesQuantity)} = ${formatMoney(formComic.salesValue)}
        </h4>
        <div>
          <button className="link-button" onClick={() => deleteStock(formComic)}>delete</button>&nbsp;&nbsp;•&nbsp;&nbsp;
          <button className="link-button" onClick={handleEditMode}>edit</button>&nbsp;&nbsp;•&nbsp;&nbsp;
          <Link to='/admin-add-back-issue' state={{ copyComic: formComic }} className="link-button">copy</Link>&nbsp;&nbsp;•&nbsp;&nbsp;
          <EbayControls comic={formComic} setComic={setFormComic} setResult={setResult} />
          {result !== '' ? (<div>{result}</div>) : ''}
          {addStock && <button type="button" onClick={(e) => addStock(e, formComic)} className="link-button right">add</button>}
        </div>
      </div>
    </div>);
}

export default ViewGenericItem;