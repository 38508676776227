import React, { useEffect, useState } from 'react'
import FeedbackItem from './FeedbackItem'
import { getFeedback } from '../../API'
import AdminNavigation from '../admin/AdminNavigation'

const FeedbackList: React.FC = () => {
  const [feedbacks, setFeedbacks] = useState<IFeedback[]>([])

  useEffect(() => {
    fetchFeedbacks()
  }, [])

  const fetchFeedbacks = (): void => {
    // console.log(`fetchFeedbacks()`);
    getFeedback()
      .then(({ data: { feedbacks } }: IFeedback[] | any) => setFeedbacks(feedbacks))
      .catch((err: Error) => console.log(err))
  }

  // const handleUpdateFeedback = (feedback: IFeedback): void => {
  //   updateFeedback(feedback)
  //     .then(({ status, data }) => {
  //       if (status !== 200) {
  //         throw new Error('Error! Feedback not updated')
  //       }
  //       setFeedbacks(data.feedbacks)
  //     })
  //     .catch((err) => console.log(err))
  // }

  // const handleDeleteFeedback = (_id: string): void => {
  //   deleteFeedback(_id)
  //     .then(({ status, data }) => {
  //       if (status !== 200) {
  //         throw new Error('Error! Feedback not deleted')
  //       }
  //       setFeedbacks(data.feedbacks)
  //     })
  //     .catch((err) => console.log(err))
  // }

  return (
    <>
      <article>
        <h1>My Feedbacks</h1>
          {feedbacks && feedbacks.map((feedback: IFeedback) => (
            <FeedbackItem
              key={feedback._id}
              // updateFeedback={handleUpdateFeedback}
              // deleteFeedback={handleDeleteFeedback}
              feedback={feedback}
            />
          ))}
      </article>
      <nav><AdminNavigation /></nav>
      <aside></aside>
    </>
  )
}

export default FeedbackList
