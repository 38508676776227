import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { deleteStock, getCurrentOrder, searchStocksAdmin, updateOrder, updateStock } from '../../API';
import AdminNavigation from './AdminNavigation'
import CurrentOrder from '../order/CurrentOrder';
import { updateOrderState } from '../../utils/OrderCalculations';
import { getAdminFromStorage } from '../../utils/UserHandler';
import { State } from '../../App';
import ViewEditStockItemSwitcher from '../admin-stockcontrol/ViewEditStockItemSwitcher';
import Swal from 'sweetalert2';
import { getPriceComicB } from '../../utils/MoneyFormats';
import { createOrderItem } from '../../common';
import AccountOrderHistoryItem from '../account/AccountOrderHistoryItem';
import AdminSearchEbayDetails from './AdminSearchEbayDetails';

type Props = {
  state: State,
  setState: any,
  order: IOrder,
  setOrder: any,
  batches: IBatch[],
}

const AdminSearchStock: React.FC<Props> = ({ state, setState, order, setOrder, batches }) => {

  const firstOrderItemRef = useRef<HTMLDivElement>(null);
  const [foundOrders, setFoundOrders] = useState<IOrder[]>([]);
  const [ebayInfos, setEbayInfos] = useState<IEbayInfo[]>([]);
  const [searchTerm, setSearchTerm] = useState<any>('');
  const [searchTermInput, setSearchTermInput] = useState<any>('');
  const [stock, setStock] = useState<IComic[]>([])

  useEffect(() => {
    fetchCurrentOrder();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (searchTerm !== '') {
      fetchStocks();
    }
  }, [searchTerm]) // eslint-disable-line react-hooks/exhaustive-deps


  const fetchStocks = (): void => {
    searchStocksAdmin(searchTerm)
      .then(({ data: { comics, orders, ebayInfos } }: IComic[] | any) => { setStock(comics); setFoundOrders(orders); setEbayInfos(ebayInfos) })
      .catch((err: Error) => console.log(err))
  }

  const update = (order: IOrder, transactions: ITransaction[], credit: number) => {

    // transactions = transactions ? transactions : [];
    // setTransactions(transactions);
    // credit = credit ? credit : 0;
    // setCredit(credit);

    setOrder(updateOrderState(order, transactions, credit, getAdminFromStorage()));
    // console.log('transactions' + JSON.stringify(transactions))
    // setTransactions(transactions ? transactions : []);
    updateState(order, transactions, credit);

    addHighlightToFirstOrderItem();
  }

  const updateState = (order: IOrder, transactions: ITransaction[], credit: number) => {
    setOrder(updateOrderState(order, transactions, credit, getAdminFromStorage()));
  }

  const fetchCurrentOrder = () => {
    // console.log(`fetchCurrentOrder()`);
    getCurrentOrder()
      .then(({ data: { order, transactions, credit } }: IOrder[] | any) => {
        update(order, transactions, credit);
      })
      .catch((err: Error) => console.log(err));
  }

  const addHighlightToFirstOrderItem = (): void => {
    if (firstOrderItemRef && firstOrderItemRef.current) {
      firstOrderItemRef.current.className = 'order-item highlight-order-item';
    }
  }

  const updateSearchTermInput = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTermInput(event.target.value);
  }

  const handleKeySearch = (event: any) => {
    if (event.key === 'Enter') {
      // console.log('search for = ' + searchTermInput);
      setSearchTerm(searchTermInput);
    }
  }

  const handleUpdateStock = (comic: IComic): void => {
    // console.log(comic);
    updateStock(comic)
      .then(({ status }) => {
        if (status !== 200) {
          throw new Error('Error! Stock not updated')
        }
        fetchStocks();
      })
      .catch((err) => console.log(err))
  }

  const handleDeleteStock = (comic: IComic): void => {
    if (comic._id !== undefined) {

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {

          if (comic._id !== undefined) {
            deleteStock(comic._id)
              .then(({ status }) => {
                if (status !== 200) {
                  throw new Error('Error! Stock not updated')
                }
                fetchStocks();

                Swal.fire(
                  'Deleted!',
                  'Your file has been deleted.',
                  'success'
                )
              })
              .catch((err) => console.log(err))
            Swal.fire(
              'NOT Deleted!',
              'Your file was NOT deleted.',
              'error'
            )
          }
        }
      })
    }
  }


  const handleAddComicButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, comic: IComic): void => {
    e.stopPropagation();
    handleAddComic(comic);
  }

  const handleAddComic = (comic: IComic): void => {
    // console.log(comic);

    const orderItem = order.orderItems.find((i) => i.comicId === comic.comicId);
    if (orderItem && orderItem !== undefined) {
      // update
      order.orderItems.splice(order.orderItems.indexOf(orderItem), 1);
      orderItem.quantity++;

      // add at top of array
      const newOrderItems = [orderItem, ...order.orderItems,];
      order.orderItems = newOrderItems;
    }
    else {
      const price = getPriceComicB(comic, comic.batch);
      // console.log(`comic.comicId ` + comic.comicId + ' price = ' + price)
      // add
      const newOrderItems = [
        createOrderItem(comic, price),
        ...order.orderItems
      ];
      order.orderItems = newOrderItems;
    }
    updateOrder(order)
      .then(({ data: { order, transactions, credit } }: IOrder[] | any) => {
        update(order, transactions, credit);
      })
      .catch((err) => console.log(err))
  }

  console.log(foundOrders);

  return (
    <>
      <article>
        <input type="text" value={searchTermInput} onChange={updateSearchTermInput} onKeyUp={handleKeySearch} required />
        {/* <button className='link-button' onClick={handleKeySearch}>Search Stock</button><br /> */}
        {stock && stock.map((comic: IComic) => (
          <div key={comic._id}>
            <ViewEditStockItemSwitcher
              batches={batches}
              updateStock={handleUpdateStock}
              deleteStock={handleDeleteStock}
              addStock={handleAddComicButton}
              comic={comic}
            />
            {foundOrders && foundOrders.length > 0 &&
              <AccountOrderHistoryItem
                order={foundOrders.find((order) => order.orderItems.filter((item) => item.comicId === comic.comicId).length > 0)}
                adminInfo={true}
              />}
            {ebayInfos && ebayInfos.length > 0 && 
              <AdminSearchEbayDetails 
                order={order} 
                ebayInfo={ebayInfos.find((ebayInfo) => ebayInfo && ebayInfo.comicId === comic.comicId)}
              />}
          </div>
        ))}
      </article>
      <nav><AdminNavigation /></nav>
      <aside>
        <CurrentOrder
          order={order}
          setOrder={setOrder}
          state={state}
          setState={setState}
          firstOrderItemRef={firstOrderItemRef}
          update={update}
          isMobileOrder={false}
        />
        {state.showMobileOrder ?
          (<div className='mobile-order-list'>
            <CurrentOrder
              order={order}
              setOrder={setOrder}
              state={state}
              setState={setState}
              firstOrderItemRef={firstOrderItemRef}
              update={update}
              isMobileOrder={true}
            />
          </div>) : ''}
      </aside>
    </>
  )
}

export default AdminSearchStock