import React, { ChangeEvent, useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Login from './login/Login';
import SignUp from './login/SignUp';
import './Header.scss';
import { clearUserInStorage, getAdminFromStorage, getUserNameFromStorage } from '../utils/UserHandler';
import { getURLFromTypes, ItemType, PeriodType } from '../common';
import { State } from '../App';
import FeedbackDialog from './feedback/FeedbackDialog';
import CreditDialog from './general/CreditDialog';
import SearchHistory from './SearchHistory';

type Props = {
  siteSettings: ISiteSettings,
  state: State,
  setState: any,
  order: IOrder,
  setOrder: any,
}

const Header: React.FC<Props> = ({ siteSettings, state, setState, order, setOrder }) => {
  
  const [userName, setUserName] = useState<any>(false);
  const [showSearchHistory, setShowSearchHistory] = useState<any>(false);
  const [searchTermInput, setSearchTermInput] = useState<any>(state.searchTerm);

  const navigate = useNavigate();

  useEffect(() => {
    setUserName(getUserNameFromStorage());
  }, [])

  const handleSignupClick = () => {
    setState({
      ...state,
      loginOpen: false,
      signupOpen: !state.signupOpen,
    })
  }

  const handleLoginClick = () => {
    setState({
      ...state,
      loginOpen: !state.loginOpen,
      signupOpen: false,
    })
  }

  const handleLogout = () => {
    clearUserInStorage();
    // don't need to close everything-filter as will be reloaded
    window.location.reload();
  }

  // COPY AND PASTE THIS, BECAUSE REACT IS SHIT
  const doSearch = (searchTermInput: string) => {

    setShowSearchHistory(false);

    if (searchTermInput === '') {
      navigate(state.lastMode?.periodType === PeriodType.NEW ? '/' : '/back-issues')
      setState({ ...state, searchTerm: searchTermInput, lastMode: undefined });
    }
    else {
      if (!state.lastMode) {
        setState({ ...state, searchTerm: searchTermInput, lastMode: state.mode });
      }
      else {
        setState({ ...state, searchTerm: searchTermInput });
      }
      navigate("/search");
    }
  }

  const handleImgSearch = () => {
    // console.log('search for = ' + searchTermInput);
    doSearch(searchTermInput);
  }

  const handleClearSearch = () => {
    setSearchTermInput('');
    setState({ ...state, searchTerm: '' });
  }

  const handleKeySearch = (event: any) => {
    setShowSearchHistory(false);
    if (event.key === 'Enter') {
      // console.log('search for = ' + searchTermInput);
      doSearch(searchTermInput);
    }
  }

  
  const outsideSearchClick = (/*event?: HTMLDivElement*/) => {
    setShowSearchHistory(false);
    // console.log(event ? event : '');
  }

  const handleSearchClick = () => {
    setShowSearchHistory(true);
  }

  const updateSearchTermInput = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTermInput(event.target.value);
  }

  const handleSearchHistory = (searchTerm: string) => {
    setSearchTermInput(searchTerm);
    doSearch(searchTerm);
  }

  const handleClearSearchHistory = () => {
    setOrder({ ...order, searchHistory:[] })
  }

  const handleNavigationLink = ( itemType: ItemType, periodType?: PeriodType ) => {
    setState({ ...state, mode: { itemType: itemType, periodType: periodType}, batch: 'all', lastMode: undefined, searchTerm: '', showMobileMenu: false });
    navigate(getURLFromTypes(itemType, periodType));
  }

  const handleAboutUs = () => {
    navigate("/about-us");
  }

  const handleShowMobileOrder = () => {
    setState({ ...state, showMobileOrder: true });
  }

  const handleShowMobileMenu = () => {
    setState({ ...state, showMobileMenu: !state.showMobileMenu });
  }

  const handleOpenFeedback = () => {
    setState({ ...state, feedbackOpen: true });
  }

  const handleCloseFeedbackDialog = () => {
    setState({ ...state, feedbackOpen: false });
  }

  const handleCloseCreditDialog = () => {
    setState({ ...state, creditDialogOpen: false });
  }

  const handleOpenProfile = () => {
    setState({ ...state, profileMenuOpen: true });
  }

  const handleLinkClick = () => {
    setState({ ...state, profileMenuOpen: false });
  }

  const handleCloseProfile = (event: React.MouseEvent<HTMLDivElement>) => {
    setState({ ...state, profileMenuOpen: false });

    console.log(event.clientX, event.clientY);

    event.persist();

    // handle the click on the element below the transparent div, after it's disappeared.
    setTimeout(() => {
      const targetElement = document.elementFromPoint(
        event.clientX,
        event.clientY
      );
      // Trigger a synthetic click event on the target element
      if (targetElement) {
        const clickEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        });
        targetElement.dispatchEvent(clickEvent);
      }
    }, 0);
  }

  return (
    <>
      <header>
        <div className="search-wrapper">
          <img className="search-image" src='/img/search-icon.svg' alt="search icon" onClick={handleImgSearch} />
          <input type="text" value={searchTermInput} className="search-input" onChange={updateSearchTermInput} onKeyUp={handleKeySearch} onClick={handleSearchClick} required/>
          <img className="clear-search-image" src='/img/x-light.svg' alt="search icon" onClick={handleClearSearch} />
          {(showSearchHistory) && 
            <SearchHistory order={order} handleSearchHistory={handleSearchHistory} handleClearSearchHistory={handleClearSearchHistory} outsideSearchClick={outsideSearchClick}/>}
        </div>
        <div className="mobile-banner">
          <button className='show-mobile-menu-button' onClick={handleShowMobileMenu}><img className='show-mobile-menu-image' src="/img/hamburger.svg" alt='hamburger menu' /></button>
          <button className='show-mobile-order-button' onClick={handleShowMobileOrder}><img className='show-mobile-order-image' src="/img/purchase.svg" alt='shopping cart' /></button>
          {state.showMobileMenu ?
            (<div className='mobile-menu-wrapper'>
              <div className='mobile-menu'>
                <div className='mobile-menu-item' onClick={() => handleNavigationLink(ItemType.COMIC, PeriodType.NEW)}>New Stock</div>
                <div className='mobile-menu-item' onClick={() => handleNavigationLink(ItemType.COMIC, PeriodType.BACK_ISSUE)}>Back Issues</div>
                {(false && getAdminFromStorage()) ? (<div className='mobile-menu-item' onClick={() => handleNavigationLink(ItemType.COMIC, PeriodType.PRE_ORDER)}>Pre-orders</div>) : ''}
                {!siteSettings.hideGames && <div className='mobile-menu-item' onClick={() => handleNavigationLink(ItemType.GAME)}>Games</div>}
                {!siteSettings.hideAccessories && <div className='mobile-menu-item' onClick={() => handleNavigationLink(ItemType.ACCESSORY)}>Accessories</div>}
                <div className='mobile-menu-item' onClick={handleOpenFeedback}>Feedback</div>
                <div className='mobile-menu-item' onClick={handleAboutUs}>About Us</div>
              </div>
            </div>)
            : ''}
        </div>
        <div className="banner">
          <div className="left-banner">
            <Link to="/" className="logo-link">
              <div className="header-logo">
                <img src="/img/logo.svg" alt="Isle of Avalon Logo" />
              </div>
              <div className="ioa-titles">
                <div className="ioa-main-title">Isle of Avalon</div>
                <div className="ioa-sub-title">Comics and Back Issues</div>
                <div className="ioa-sub-title">Shipping to all of Australia</div>
              </div>
            </Link>
          </div>
          <div className="banner-menu">
            <div className="big-images">
              <div className="header-big-image">
                <img src="/img/header.svg" alt="Big header" />
              </div>
              <div className="header-big-image image-flip">
                <img src="/img/header.svg" alt="Flipped big header" />
              </div>
            </div>
            <div className='header-top-menu'>
              <div className="header-menu-left">&nbsp;</div>
              <div className="header-menu-centre">
                <Link to="/new-comics" className="header-menu-link" onClick={() => handleNavigationLink(ItemType.COMIC, PeriodType.NEW)} >New Stock</Link><span className="header-menu-bullet">&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                <Link to="/back-issues" className="header-menu-link" onClick={() => handleNavigationLink(ItemType.COMIC, PeriodType.BACK_ISSUE)}>Back Issues</Link><span className="header-menu-bullet"></span>
                {false && getAdminFromStorage() ? (<>&nbsp;&nbsp;•&nbsp;&nbsp;<Link to="/pre-orders" className="header-menu-link" onClick={() => handleNavigationLink(ItemType.COMIC, PeriodType.PRE_ORDER)}>Pre-Orders</Link><span className="header-menu-bullet"></span></>) : ''}
                {!siteSettings.hideGames && <>&nbsp;&nbsp;•&nbsp;&nbsp;<Link to="/games" className="header-menu-link" onClick={() => handleNavigationLink(ItemType.GAME)}>Games</Link><span className="header-menu-bullet"></span></>}
                {!siteSettings.hideAccessories && <>&nbsp;&nbsp;•&nbsp;&nbsp;<Link to="/accessories" className="header-menu-link" onClick={() => handleNavigationLink(ItemType.ACCESSORY)}>Accessories</Link></>}
                <span className='header-menu-centre-links'>
                  <span className='header-menu-sm-bullet'>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                  <button className="link-button" onClick={handleOpenFeedback}>Feedback</button>
                  <span className='header-menu-sm-bullet'>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                  <Link to="/about-us">About Us</Link>
                  {getAdminFromStorage() ?
                    (<>
                      <span className='header-menu-sm-bullet'>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
                      <Link to="/admin-orders-awaiting-postage">Admin</Link>
                    </>)
                    : ""}
                </span>
              </div>
              <div className="header-menu-right">
                {state.profileMenuOpen ? (<div className='everything-filter' onClick={handleCloseProfile} style={{ pointerEvents: 'auto' }}></div>) : ''}
                {userName === false ?
                  (<SignUpLoginContainer state={state} handleLoginClick={handleLoginClick} handleSignupClick={handleSignupClick} />) :
                  (<>

                    <div className='header-menu-right-profile'>

                      {/* {userName} <span className='logout'> [<button className="link-button" onClick={handleLogout}>logout</button>]</span> */}

                      {state.profileMenuOpen ?
                        (<div className='profile-menu-wrapper'>
                          <div className='profile-menu'>
                            <Link to="/account-orders" className='profile-menu-item' onClick={handleLinkClick}>Orders</Link>
                            <div className='profile-menu-item' onClick={handleLogout}>Log Out</div>
                          </div>
                        </div>) : ''}
                      <button className="link-button" onClick={handleOpenProfile}>
                        Your Account
                      </button>
                    </div>
                  </>)}
              </div>
            </div>
          </div>
        </div>
        {state.feedbackOpen ?
          (<FeedbackDialog handleCloseFeedbackDialog={handleCloseFeedbackDialog} />)
          : ''}
        {state.creditDialogOpen ?
          (<CreditDialog targetUserId={state.targetUserId} handleCloseCreditDialog={handleCloseCreditDialog} />)
          : ''}
        {siteSettings.warningText && siteSettings.warningText.trim() !== '' &&
          <div className='warning-banner' dangerouslySetInnerHTML={{__html: siteSettings.warningText}}></div>}
      </header >
    </>
  )
}

type SignUpLoginContainerProps = {
  state: State,
  handleSignupClick: React.MouseEventHandler<HTMLButtonElement>,
  handleLoginClick: React.MouseEventHandler<HTMLButtonElement>
}

const SignUpLoginContainer: React.FC<SignUpLoginContainerProps> = ({ state, handleSignupClick, handleLoginClick }) => {

  return (
    <div className='header-menu-right-signup-login'>
      {state.loginOpen ? <Login /> : ""}
      <span className="Card--button">
        <button onClick={handleLoginClick} className="standard-button login-signup">Login</button>
      </span>
      {/* &nbsp;
      •
      &nbsp; */}
      {state.signupOpen ? <SignUp /> : ""}
      <span className="Card--button">
        <button onClick={handleSignupClick} className="standard-button login-signup">Sign Up</button>
      </span>
    </div>
  );
}

export default Header
