import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { addBatch, getBatches } from '../../API';
import AdminNavigation from '../admin/AdminNavigation'
import BatchItemEdit from './BatchItemEdit';
import { blankBatch } from '../../common';

type Props = {
  setBatches: (batches:IBatch[]) => void;
}

const AddBatchItem: React.FC<Props> = ({setBatches}) => {

  const [batch, setBatch] = useState<IBatch>(blankBatch());

  const handleSubmit = (event: React.FormEvent<HTMLElement>): void => {
    event.preventDefault();
    addBatch(batch)
      .then(({ status }) => {
        Swal.fire("Success", "Stock added", "success").then(() => {
          console.log('status = ' + status);
          if (status !== 201) {
            throw new Error('Error! Stock not updated')
          }
          else {
            const newBatch = {
              ...batch,
              batchId: ''
            }
            setBatch(newBatch);

            getBatches().then(({ data: { batches } }: IBatch[] | any) => { 
              console.log('batches');
              setBatches(batches); 
            })
          }
        })
      }).catch((err) => console.log(err));
  }

  const handleCancel = () => {
    setBatch(blankBatch());
  }

  return (
    <>
      <article>
        <BatchItemEdit batch={batch} setBatch={setBatch} handleCancel={handleCancel} handleFormSubmit={handleSubmit} />
      </article>
      <nav><AdminNavigation /></nav>
      <aside></aside>
    </>
  )
}

export default AddBatchItem