import React, { useState } from 'react'
import { getAdminFromStorage } from '../../utils/UserHandler';
import AddressAutocomplete from './AddressAutocomplete';
type BillingAddressProps = {
  order: IOrder,
  setOrder: (order: IOrder) => void,
  libraries: string[],
  handleNext: () => void,
  handleBack: () => void,
}

const BillingAddress: React.FC<BillingAddressProps> = ({ order, setOrder, libraries, handleNext, handleBack }) => {

  const [shippingIsSameAsBilling, setBillingIsSameAsBilling] = useState(order.shippingIsPickup ? false : order.shippingIsSameAsBilling);
  order.shippingIsSameAsBilling = shippingIsSameAsBilling;

  // console.log(order);

  const [billingAddress, setBillingAddress] = useState<IAddress>(order.billingAddress ? order.billingAddress : {
    familyName: '',
    givenName: '',
    addressLine1: "",
    // addressLine2: "",
    countryCode: "AUD",
    suburb: "",
    state: "",
    postalCode: "",
  })

  const [formDataValid, setFormDataValid] = useState({
    familyNameValid: true,
    givenNameValid: true,
    addressLine1Valid: true,
    suburbValid: true,
    stateValid: true,
    postalCodeValid: true,
  })

  const handleForm = (e: React.FormEvent<HTMLInputElement>): void => {

    const newBillingAddress = {
      ...billingAddress,
      [e.currentTarget.id]: e.currentTarget.value,
    };

    setBillingAddress(newBillingAddress);
    setOrder({
      ...order,
      billingAddress: newBillingAddress
    });

    setFormDataValid({
      ...formDataValid,
      [e.currentTarget.id + 'Valid']: e.currentTarget.value.length > 0,
    })
  }

  const handleIsDiabledChange = () => {
    const newValue = !shippingIsSameAsBilling;
    // console.log('shippingIsPickup ' + shippingIsPickup);
    // console.log('shippingIsSameAsBilling ' + newValue);
    setBillingIsSameAsBilling(newValue);
    setOrder({
      ...order,
      shippingIsSameAsBilling: newValue
    });
  }

  const checkField = (e: React.FormEvent<HTMLInputElement>): void => {
    setFormDataValid({
      ...formDataValid,
      [e.currentTarget.id + 'Valid']: (e.currentTarget.value.length > 0),
    })
  }

  const isFormValid = () => {
    const isFormValid =
      (getAdminFromStorage() && order.shippingIsPickup) ||
      shippingIsSameAsBilling ||
      (formDataValid.familyNameValid &&
        formDataValid.givenNameValid &&
        formDataValid.addressLine1Valid &&
        formDataValid.suburbValid &&
        formDataValid.stateValid &&
        formDataValid.postalCodeValid);
    return isFormValid;
  }

  const lastCheck = () => {
    setFormDataValid({
      familyNameValid: (billingAddress.familyName ? billingAddress.familyName.length > 0 : false),
      givenNameValid: (billingAddress.givenName ? billingAddress.givenName.length > 0 : false),
      addressLine1Valid: (billingAddress.addressLine1 ? billingAddress.addressLine1.length > 0 : false),
      suburbValid: (billingAddress.suburb ? billingAddress.suburb.length > 0 : false),
      stateValid: (billingAddress.state ? billingAddress.state.length > 0 : false),
      postalCodeValid: (billingAddress.postalCode ? billingAddress.postalCode.length > 0 : false),
    });

    // manual check as react is shit.

    const isValid = 
      (getAdminFromStorage() && order.shippingIsPickup) ||
      shippingIsSameAsBilling ||
      (billingAddress.familyName !== undefined && billingAddress.familyName.length > 0 &&
        billingAddress.familyName !== undefined && billingAddress.familyName.length > 0 &&
        billingAddress.givenName !== undefined && billingAddress.givenName.length > 0 &&
        billingAddress.addressLine1 !== undefined && billingAddress.addressLine1.length > 0 &&
        billingAddress.suburb !== undefined && billingAddress.suburb.length > 0 &&
        billingAddress.state !== undefined && billingAddress.state.length > 0 &&
        billingAddress.postalCode !== undefined && billingAddress.postalCode.length > 0);

    // console.log(isValid);

    if (isValid) {
      setOrder({
        ...order,
        billingAddress: billingAddress
      });

      handleNext();
    }
  }
  
  const handlePlaceSelected = (place: google.maps.places.PlaceResult | undefined) => {

    if (place) {

      if (!place.vicinity !== undefined && place.formatted_address !== undefined) {

        const subber = place.vicinity && place.formatted_address ? place.formatted_address?.substring(place.formatted_address?.indexOf(place.vicinity)+place.vicinity.length).trim() : '';

        const addressLine1 = place.name ? place.name : '';
        const suburb = place.vicinity ? place.vicinity : '';
        const state = subber.substring(0, subber.indexOf(' '));
        const postalCode = subber.substring(subber.indexOf(state)+state.length, subber.indexOf(','));

        setBillingAddress({
          ...billingAddress,
          addressLine1: addressLine1,
          suburb: suburb,
          state: state,
          postalCode: postalCode,
        })

        setFormDataValid({
          ...formDataValid,
          addressLine1Valid: (addressLine1 ? addressLine1.length > 0 : false),
          suburbValid: (suburb ? suburb.length > 0 : false),
          stateValid: (state ? state.length > 0 : false),
          postalCodeValid: (postalCode ? postalCode.length > 0 : false),
        });
      }
    }
  }

  return (
    <div>
      <div className='address-form'>
        <div>
          <h3>Billing Address</h3>
        </div>
        <form className='Form'>
          <div className='form-rows'>
      
            <div>
              {getAdminFromStorage() || !order.shippingIsPickup ?
              (<><input type='checkbox' id='sameAsBillingOption' checked={shippingIsSameAsBilling} onChange={handleIsDiabledChange} /><label htmlFor='sameAsBillingOption' className='checkbox-label'>Same As Shipping Address</label></>)
              : ''}

              <div className="nameContainer">
                <div className="givenNameContainer">
                  <label htmlFor='givenName' className='block'>Given Name</label>
                  <input onChange={handleForm} onBlur={checkField} type='text' id='givenName' disabled={shippingIsSameAsBilling} value={billingAddress.givenName} className={shippingIsSameAsBilling || formDataValid.givenNameValid ? '' : 'invalid'} />
                </div>
                <div className="familyNameContainer">
                  <label htmlFor='familyName' className='block'>Family Name</label>
                  <input onChange={handleForm} onBlur={checkField} type='text' id='familyName' disabled={shippingIsSameAsBilling} value={billingAddress.familyName} className={shippingIsSameAsBilling || formDataValid.familyNameValid ? '' : 'invalid'} />
                </div>
              </div>

              {!shippingIsSameAsBilling ?
                (<div className="addressContainer">
                  <label htmlFor='addressSearch'>Search Address</label>
                  <AddressAutocomplete onPlaceSelected={handlePlaceSelected} libraries={libraries} />
                </div>) : ''}
                
              <div className="addressContainer">
                <label htmlFor='addressLine1' className='block'>Address</label>
                <input onChange={handleForm} onBlur={checkField} type='text' id='addressLine1' disabled={shippingIsSameAsBilling} value={billingAddress.addressLine1} className={shippingIsSameAsBilling || formDataValid.addressLine1Valid ? '' : 'invalid'} />
              </div>

              <div className="addressDetailsContainer">
                <div className="suburbContainer">
                  <label htmlFor='suburb'>Suburb</label>
                  <input onChange={handleForm} onBlur={checkField} type='text' id='suburb' disabled={shippingIsSameAsBilling} value={billingAddress.suburb} className={shippingIsSameAsBilling || formDataValid.suburbValid ? '' : 'invalid'} />
                </div>

                <div className="stateContainer">
                  <label htmlFor='state'>State</label>
                  <input onChange={handleForm} onBlur={checkField} type='text' id='state' disabled={shippingIsSameAsBilling} value={billingAddress.state} className={shippingIsSameAsBilling || formDataValid.stateValid ? '' : 'invalid'} />
                </div>

                <div className="postalCodeContainer">
                  <label htmlFor='postalCode'>Postal Code</label>
                  <input onChange={handleForm} onBlur={checkField} type='text' id='postalCode' disabled={shippingIsSameAsBilling} value={billingAddress.postalCode} className={shippingIsSameAsBilling || formDataValid.postalCodeValid ? '' : 'invalid'} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <br />
      <div className='payment-dialog-button-container'>
        <button 
          className="link-button back-link" 
          onClick={() => handleBack()}>
          <img src='/img/left-arrow.svg' alt='back' />
        </button>
        
        {order.toPay <= 0.01 && order.total >= 0.01 ?
        (<button
          onClick={() => lastCheck()}
          className='standard-button'
          disabled={!isFormValid()}
        >
          Confirmation
        </button>) :
        (<button
          onClick={() => lastCheck()}
          className='standard-button'
          disabled={!isFormValid()}
        >
          Continue to Payment
        </button>)}
      </div>
    </div>
  )
}


export default BillingAddress