import React, { useState } from 'react'
import BatchItemEdit from './BatchItemEdit';
import BatchItemView from './BatchItemView';
import { clearStock, updateBatch } from '../../API';
import BatchItemUpload from './BatchItemUpload';
import Swal from 'sweetalert2';

type Props = {
  batchInput: IBatch,
  handleDeleteBatch: (batch: IBatch) => void,
}

const BatchItem: React.FC<Props> = ({ batchInput, handleDeleteBatch }) => {

  const [mode, setMode] = useState('view');
  const [batch, setBatch] = useState<IBatch>(batchInput);

  const handleEditMode = () => {
    setMode('edit');
  }

  const handleUploadMode = () => {
    setMode('upload');
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    updateBatch(batch);
    setMode('view');
  }

  const handleCancel = () => {
    setBatch(batchInput);
    setMode('view');
  }


  const handleClearStock = (batch: IBatch): void => {
    if (batch._id !== undefined) {

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {

          if (batch._id !== undefined) {
            clearStock(batch)
              .then(({ status, data }) => {
                if (status === 202) {
                  Swal.fire(
                    'NOT Deleted',
                    'Batch has NOT been deleted.',
                    'error'
                  )
                }
                else if (status === 200) {
                  // fetchBatches();

                  setMode('view');

                  Swal.fire(
                    'Deleted!',
                    data.message,
                    'success'
                  )
                }
                else {
                  throw new Error('Error!')
                }
              })
              .catch((err) => console.log(err))
          }
        }
      })
    }
  }

  return (
    <>
      {mode === 'edit' ?
        (<BatchItemEdit batch={batch} setBatch={setBatch} handleCancel={handleCancel} handleFormSubmit={handleFormSubmit} />) :
        mode === 'upload' ?
          (<BatchItemUpload batch={batch} setBatch={setBatch} handleCancel={handleCancel} handleFormSubmit={handleFormSubmit} />) :
          (<BatchItemView batch={batch} handleEditMode={handleEditMode} handleUploadMode={handleUploadMode} handleDeleteBatch={handleDeleteBatch} handleClearStock={handleClearStock} />)}
      <hr />
    </>
  )
}

export default BatchItem;