import React from 'react'
import { OldBatchType } from '../../common';

type Props = {
  batch: IBatch, 
  setBatch: (batch: IBatch) => void,
}

const BatchTypeDropDown: React.FC<Props> = ({batch, setBatch}) => {

  const batchTypes = [OldBatchType.DIAMOND, OldBatchType.BACK_ISSUE, OldBatchType.PRE, OldBatchType.GAME];

  const handleChange = (e: React.FormEvent<HTMLSelectElement>): void => {
    const batchType = batchTypes?.find(batchType => batchType === e.currentTarget.value);
    if (batchType) {
      setBatch({
        ...batch,
        type: batchType,
      })
    }
  }

  return (
    <>
      <select className='inline auto-width' value={batch.type} onChange={handleChange}>
        {batchTypes && batchTypes.map((batchType, key) => <option key={key}>{batchType}</option> )}
      </select>
    </>
  )
}

export default BatchTypeDropDown