import React, { useEffect, useState } from 'react'
import UserItem from './UserItem'
import { getUsers } from '../../API'
import AdminNavigation from './AdminNavigation'
import { State } from '../../App'

type Props = {
  state: State,
  setState: any,
}

const UserList: React.FC<Props> = ({state, setState}) => {
  const [users, setUsers] = useState<IUser[]>([])

  useEffect(() => {
    fetchUsers()
  }, [])

  const fetchUsers = (): void => {
    getUsers()
      .then(({ data: { users } }: IUser[] | any) => setUsers(users))
      .catch((err: Error) => console.log(err))
  }

  return (
    <>
      <article>
        <h1>My Users</h1>
        <br/>
        <div className='column-centre'>
          {users.map((user: IUser) => (
            <UserItem
              state={state}
              setState={setState}
              key={user._id}
              user={user}
            />
          ))}
        </div>
      </article>
      <nav><AdminNavigation/></nav>
      <aside></aside>
    </>
  )
}

export default UserList
