import React from 'react'
import { getSupplierTypeName, SupplierType } from '../../common';

type Props = {
  batch: IBatch, 
  setBatch: (batch: IBatch) => void,
}

const BatchTypeDropDown: React.FC<Props> = ({batch, setBatch}) => {

  const supplierTypes = [SupplierType.DIAMOND, SupplierType.LPG, SupplierType.AUCTION, SupplierType.LOCAL_PERSON, SupplierType.LOCAL_SHOP];

  const handleChange = (e: React.FormEvent<HTMLSelectElement>): void => {
    // console.log(e.currentTarget.value);
    const supplierType = supplierTypes?.find(supplierType => supplierType === e.currentTarget.value);
    // console.log(supplierType);
    if (supplierType) {
      setBatch({
        ...batch,
        supplierType: supplierType,
      })
    }
  }

  return (
    <>
      <select className='inline auto-width' value={batch.supplierType} onChange={handleChange}>
        {supplierTypes && supplierTypes.map((supplierType, key) => <option key={key} value={supplierType}>{getSupplierTypeName(supplierType)}</option> )}
      </select>
    </>
  )
}

export default BatchTypeDropDown