import React, { useEffect, useState } from 'react'
import AdminNavigation from './AdminNavigation'
import OrderHistoryItem from './OrderHistoryItem'
import { getOrderAwaitingPostage } from '../../API'
import { State } from '../../App'

type Props = {
  state: State,
  setState: any,
}

const OrderAwaitingPostageList: React.FC<Props> = ({ state, setState }) => {
  const [orders, setOrders] = useState<IOrder[]>([])

  useEffect(() => {
    fetchOrders()
  }, [])

  const fetchOrders = (): void => {
    getOrderAwaitingPostage()
      .then(({ data: { orders } }: IOrder[] | any) => setOrders(orders))
      .catch((err: Error) => console.log(err))
  }

  return (
    <>
      <article>
        <h1>Orders Awaiting Postage</h1>
        <br/>
        <div className='column-centre'>
          {/* <AddOrder saveOrder={handleSaveOrder} /> */}
          {orders && orders.map((order: IOrder) => (
            <OrderHistoryItem
              key={order._id}
              orderInput={order}
              state={state}
              setState={setState}
            />
          ))}
        </div>
      </article>
      <nav><AdminNavigation /></nav>
      <aside></aside>
    </>
  )
}

export default OrderAwaitingPostageList