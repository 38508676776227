import React from 'react';
import { getEbayClearComic, getEbayDeleteComic, getEbayRefreshComic, getEbayUploadComic } from '../../API';

type Props = {
  comic: IComic,
  setComic: (formComic: IComic) => void,
  setResult: any,
}


const EbayControls: React.FC<Props> = ({ comic, setComic, setResult }) => {

  const handleEbayUpload = (comic: IComic) => {
    getEbayUploadComic(comic)
      .then(({ data }) => {
        // console.log(`status: ` + status);
        // console.log(`data: ` + data);
        // console.log(`status: ` + data.message);
        setComic(data.comic);
        setResult(JSON.stringify(data.message));
      })
      .catch((err) => { console.log(err); setResult(err.message); })
  }

  const handleEbayRefresh = (comic: IComic) => {
    getEbayRefreshComic(comic)
      .then(({ data }) => {
        // console.log(`status: ` + status);
        // console.log(`data: ` + data);
        // console.log(`status: ` + data.message);
        setComic(data.comic);
        setResult(JSON.stringify(data.message));
      })
      .catch((err) => { console.log(err); setResult(err.message); })
  }

  const handleEbayClear = (comic: IComic) => {
    getEbayClearComic(comic)
      .then(({ data }) => {
        // console.log(`status: ` + status);
        // console.log(`data: ` + data);
        // console.log(`status: ` + data.message);
        setComic(data.comic);
        setResult(JSON.stringify(data.message));
      })
      .catch((err) => { console.log(err); setResult(err.message); })
  }

  const handleEbayDelete = (comic: IComic) => {
    getEbayDeleteComic(comic)
      .then(({ data }) => {
        // console.log(`status: ` + status);
        // console.log(`data: ` + data);
        // console.log(`status: ` + data.message);
        setComic(data.comic);
        setResult(JSON.stringify(data.message));
      })
      .catch((err) => { console.log(err); setResult(err.message); })
  }

  return (<>
    {comic.ebayListingId ?
      (<>
        <a href={'https://www.ebay.com.au/itm/' + comic.ebayListingId} target="_blank" rel="noreferrer">view</a>&nbsp;&nbsp;•&nbsp;&nbsp;
        <button className="link-button" onClick={() => handleEbayRefresh(comic)}>refresh</button>&nbsp;&nbsp;•&nbsp;&nbsp;
        <button className="link-button" onClick={() => handleEbayClear(comic)}>clear</button>&nbsp;&nbsp;•&nbsp;&nbsp;
        <button className="link-button" onClick={() => handleEbayDelete(comic)}>delete</button>
      </>) :
      (<button className="link-button" onClick={() => handleEbayUpload(comic)}>upload</button>)
    }
  </>);
}

export default EbayControls;