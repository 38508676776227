import React from 'react'

type Props = ComicProps & {
  updateStock: (comic: IComic) => void
}

const StockImagesItem: React.FC<Props> = ({ comic /*, updateStock*/ }) => {
  // const checkStock: string = comic.status ? `line-through` : ''
  return (
    <div className='Card'>
      <div className='Card--text'>
        <h1>{comic.comicId}</h1>
      </div>
    </div>
  )
}

export default StockImagesItem
