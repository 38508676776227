import React from 'react';
import { State } from '../../App';

type Props = {
  state: State,
  setState:any,
}

const AboutPage: React.FC<Props> = ({state, setState}) => {
  
  const handleOpenFeedback = () => {
    setState({...state, feedbackOpen: true});
  }

  return (
    <>
      <article>
        <h1>About Us</h1>
        <p>We are a new website, but relatively old hands at selling comics. We've been selling on E-Bay but we are trying to move toward using our own site. This one! Right here.</p>
        <p>
          You can see our <a href="https://www.ebay.com.au/usr/isleofavaloncomics?_tab=feedback#tab2" target="_blank" rel="noreferrer">E-Bay Feedback</a> and see what nice things people 
          have to say about us. Also, if there is anything on E-Bay that we haven't listed on here, give us a shout and we'll upload it here (with a discount of course) 
          so you can buy direct and we can pass on the E-Bay fees to you.
        </p>
        <p>We pride ourselves on packaging and making sure your comics get to your in the best possible condition.</p>
        <p>
          We would love some <button className="link-button" onClick={handleOpenFeedback}>feedback</button> or just say "hello" if you like! 
          We've hand-crafted this site by ourselves and would love know what you think. We know that the best way to improve our site is by <button className="link-button" onClick={handleOpenFeedback}>listening to you</button>.
        </p>
        <br/>
        <h3>We Buy Comics in Brisbane</h3>
        <p>Send us a <button className="link-button" onClick={handleOpenFeedback}>shout out</button> and we can come and check out your comic collection.</p>
      </article>
      <nav></nav>
      <aside></aside>
    </>
  );
}

export default AboutPage