
import { v4 as uuidv4 } from 'uuid';

export const getTempToken = (): string => {
  let tempToken = localStorage.getItem('tempToken');
  console.log('tempToken ' + tempToken);
  if (!tempToken) {
    clearCacheData();
    tempToken = uuidv4();
    console.log('creating tempToken ' + tempToken);
    localStorage.setItem('tempToken', tempToken);
  }
  return tempToken;
}

export const getUserNameFromStorage = () => {
  const userInStorage = localStorage.getItem('user');
  // console.log('userInStorage = ' + userInStorage);
  if (userInStorage) {
    return JSON.parse(userInStorage).email;
  }
  else return false;
}

export const getUserFromStorage = (): IUser => {
  const userInStorage = localStorage.getItem('user');
  // console.log('userInStorage = ' + userInStorage);
  if (userInStorage) {
    return JSON.parse(userInStorage) as IUser;
  }
  else return { _id: '', email: '', familyName: '', givenName: '', localPickup: false };
}

export const getAdminFromStorage = () => {
  const userInStorage = localStorage.getItem('user');
  // console.log('admin userInStorage = ' + userInStorage);
  if (userInStorage) {
    try {
      return JSON.parse(userInStorage).admin;
    }
    catch (err) {
      clearUserInStorage();
      return false;
    }
  }
  else return false;
}

export const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      // console.log(`delete ` + name)
      caches.delete(name);
    });
  });
  console.log(`Complete Cache Cleared`)
};

export const setUserInStorage = (user: any, authorisationKey: string) => {
  console.log('setUserInStorage');
  clearCacheData();
  localStorage.setItem('accessToken', authorisationKey);
  localStorage.setItem('user', JSON.stringify(user));
}

export const clearUserInStorage = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('accessToken');
}
