import React, { useEffect, useState } from 'react'
import UserList from './components/admin/UserList'
import TodoList from './components/todo/TodoList'
import { BrowserRouter as Router, Route, Routes, Outlet } from "react-router-dom";
import OrderList from './components/order/OrderList'
import AuditHistoryList from './components/audit/AuditHistoryList'
import Header from './components/Header'
import StockUpload from './components/admin-stockcontrol/StockUpload';
import StockImagesList from './components/admin-stockcontrol/StockImagesList';
import Status from './components/admin/Status';
import { ItemType, PeriodType } from './common';
import AllStockList from './components/admin-stockcontrol/AllStockList';
import AllOrderHistoryList from './components/admin/AllOrderHistoryList';
import CompleteOrderHistoryList from './components/admin/CompleteOrderHistoryList';
import AboutPage from './components/general/AboutPage';
import FeedbackList from './components/feedback/FeedbackList';
import AccountOrderHistoryList from './components/account/AccountOrderHistoryList';

import eBayApi from 'ebay-api';
import AdminEbay from './components/admin/AdminEbay';
import OrderAwaitingPostageList from './components/admin/OrderAwaitingPostageList';
import AdhocOrderHistoryList from './components/admin/AdhocOrderHistoryList';
import BatchList from './components/admin-batches/BatchList';
import AddBatchItem from './components/admin-batches/AddBatchItem';
import ZeroStockList from './components/admin-stockcontrol/ZeroStockList';
import PreOrderListCondensed from './components/admin-stockcontrol/PreOrderListCondensed';
import AdminStockList from './components/admin-stockcontrol/AdminStockList';
import AdminStockItemAdd from './components/admin-stockcontrol/AdminStockItemAdd';
import { getBatches, getSiteSettings } from './API';
import EbayStockCheck from './components/admin/EbayStockCheck';
import AdminSearchStock from './components/admin/AdminSearchStock';
import SiteSettings from './components/admin-settings/SiteSettings';

const SITE_ID = eBayApi.SiteId.EBAY_AU;
const MARKETPLACE_ID = eBayApi.MarketplaceId.EBAY_AU;
const ACCEPT_LANGUAGE = eBayApi.Locale.en_AU;
const CONTENT_LANGUAGE = eBayApi.ContentLanguage.en_AU;

interface ModeSet {
  itemType: ItemType,
  periodType: PeriodType,
}

export type State = {
  mode: ModeSet
  lastMode: ModeSet | undefined,
  batch: string,
  searchTerm: string,
  loginOpen: boolean,
  signupOpen: boolean,
  feedbackOpen: boolean,
  creditDialogOpen: boolean,
  targetUserId: string,
  profileMenuOpen: boolean,
  showMobileOrder: boolean,
  showMobileMenu: boolean,
}

const eBay = new eBayApi({
  appId: 'IsleofAv-IsleofAv-PRD-0bd186d65-a5174588',
  certId: 'PRD-bd186d656371-e26f-49b4-9dfc-1fb7',
  ruName: 'Isle_of_Avalon-IsleofAv-Isleof-qfmwaybqo',

  sandbox: false,
  autoRefreshToken: true,

  siteId: SITE_ID,
  marketplaceId: MARKETPLACE_ID,
  acceptLanguage: ACCEPT_LANGUAGE,
  contentLanguage: CONTENT_LANGUAGE,
});

// const eBay = new eBayApi({
//   appId: 'IsleofAv-IsleofAv-SBX-6bd2b6431-4137db18',
//   certId: 'SBX-bd2b6431c19e-bac0-4cd5-ac82-0066',
//   ruName: 'Isle_of_Avalon-IsleofAv-Isleof-fftwpk'

//   sandbox: true,
// autoRefreshToken: true,

// siteId: SITE_ID,
// marketplaceId: MARKETPLACE_ID,
// acceptLanguage: ACCEPT_LANGUAGE,
// contentLanguage: CONTENT_LANGUAGE,

// });

const App: React.FC = () => {
  const [state, setState] = useState<State>({
    mode: { itemType: ItemType.COMIC, periodType: PeriodType.NEW },
    lastMode: undefined,
    batch: 'all',
    searchTerm: '',
    loginOpen: false,
    signupOpen: false,
    feedbackOpen: false,
    creditDialogOpen: false,
    targetUserId: '',
    profileMenuOpen: false,
    showMobileOrder: false,
    showMobileMenu: false,
  });
  
  const [batches, setBatches] = useState<IBatch[]>([]);
  const [siteSettings, setSiteSettings] = useState<ISiteSettings>({
    hideAccessories: false,
    hideGames: false,
    warningText: '',
  });
  
  const LOADING = '...loading';
  const [order, setOrder] = useState<IOrder>({
    _id: LOADING, date: '01/01/2022', complete: false, posted: false, carriedForward: 0, orderItems: [], latestTransaction: undefined,
    searchTerms: '',
    searchHistory: [],
    shippingIsPickup: false, shippingIsSameAsBilling: false,
    toPay: 0, pnp: 0, total: 0, itemCount: 0, paid: 0, creditFrom: 0, creditForward: 0
  });

  useEffect(() => {
    getBatches().then(({ data: { batches } }) => { 
      console.log('loading batches');
      setBatches(batches); 
    })
    getSiteSettings().then(({ data: { siteSettings } }) => { 
      console.log('loading site settings');
      setSiteSettings(siteSettings); 
    })
  }, [])

  function BasicLayout() {
    return (
      <>
        <Header
          siteSettings={siteSettings}
          state={state}
          setState={setState} 
          order={order} 
          setOrder={setOrder} />
        <div id="main">
          <Outlet />
        </div>
        {/* <footer>Footer</footer> */}
      </>
    )
  }

  let libraries = ["places"];

  return (
    <Router>
      <Routes>
        <Route path="/" element={BasicLayout()}>
          {/* <Route index element={<Homepage state={state} setState={setState} order={order} setOrder={setOrder} />} /> */}
          <Route index element={<OrderList state={state} setState={setState} order={order} setOrder={setOrder} libraries={libraries} batches={batches} />} />
          <Route path="new-comics" element={<OrderList state={state} setState={setState} order={order} setOrder={setOrder} libraries={libraries} batches={batches} />} />
          <Route path="back-issues" element={<OrderList state={state} setState={setState} order={order} setOrder={setOrder} libraries={libraries} batches={batches} />} />
          <Route path="pre-orders" element={<OrderList state={state} setState={setState} order={order} setOrder={setOrder} libraries={libraries} batches={batches} />} />
          <Route path="games" element={<OrderList state={state} setState={setState} order={order} setOrder={setOrder} libraries={libraries} batches={batches} />} />
          <Route path="accessories" element={<OrderList state={state} setState={setState} order={order} setOrder={setOrder} libraries={libraries} batches={batches} />} />
          <Route path="search" element={<OrderList state={state} setState={setState} order={order} setOrder={setOrder} libraries={libraries} batches={batches} />} />
          <Route path="search/:searchTerm" element={<OrderList state={state} setState={setState} order={order} setOrder={setOrder} libraries={libraries} batches={batches} />} />
          <Route path="about-us" element={<AboutPage state={state} setState={setState} />} />
          <Route path="account-orders" element={<AccountOrderHistoryList />} />
          <Route path="todos" element={<TodoList />} />
          <Route path="admin-audit-history" element={<AuditHistoryList />} />
          <Route path="admin-user-list" element={<UserList state={state} setState={setState} />} />
          <Route path="admin-orders-awaiting-postage" element={<OrderAwaitingPostageList state={state} setState={setState} />} />
          <Route path="admin-complete-order-list" element={<CompleteOrderHistoryList state={state} setState={setState} />} />
          <Route path="admin-adhoc-order-list" element={<AdhocOrderHistoryList state={state} setState={setState} />} />
          <Route path="admin-order-list" element={<AllOrderHistoryList state={state} setState={setState} />} />
          <Route path="admin-stock-upload" element={<StockUpload />} />

          <Route path="admin-stock-images" element={<StockImagesList />} />

          <Route path="admin-batch-list" element={<BatchList />} />
          <Route path="admin-add-batch" element={<AddBatchItem setBatches={setBatches}/>} />

          <Route path="admin-stock-list" element={<AllStockList batches={batches} />} />
          <Route path="admin-zero-stock-list" element={<ZeroStockList batches={batches}/>} />
          <Route path="admin-pre-order-list-condensed/:batchId" element={<PreOrderListCondensed />} />

          <Route path="admin-new-stock-list" element={<AdminStockList itemType={ItemType.COMIC} periodType={PeriodType.NEW} batches={batches} />} />
          <Route path="admin-back-issue-list" element={<AdminStockList itemType={ItemType.COMIC} periodType={PeriodType.BACK_ISSUE} batches={batches} />} />
          <Route path="admin-pre-order-list" element={<AdminStockList itemType={ItemType.COMIC} periodType={PeriodType.PRE_ORDER} batches={batches} />} />
          <Route path="admin-games-list" element={<AdminStockList itemType={ItemType.GAME} batches={batches}/>} />
          <Route path="admin-accessories-list" element={<AdminStockList itemType={ItemType.ACCESSORY} batches={batches} />} />

          <Route path="admin-add-new-stock-issue" element={<AdminStockItemAdd itemType={ItemType.COMIC} periodType={PeriodType.NEW} batches={batches} />} />
          <Route path="admin-add-back-issue" element={<AdminStockItemAdd itemType={ItemType.COMIC} periodType={PeriodType.BACK_ISSUE} batches={batches} />} />
          <Route path="admin-add-game" element={<AdminStockItemAdd itemType={ItemType.GAME} /*periodType*/  batches={batches} />} />
          <Route path="admin-add-accessories" element={<AdminStockItemAdd itemType={ItemType.ACCESSORY} /*periodType*/ batches={batches} />} />
          <Route path="admin-ebay-check-stock" element={<EbayStockCheck state={state} setState={setState} />} />
          <Route path="admin-search-stock" element={<AdminSearchStock state={state} setState={setState} order={order} setOrder={setOrder} batches={batches} />} />

          <Route path="admin-feedback" element={<FeedbackList />} />
          <Route path="admin-status" element={<Status />} />
          <Route path="admin-ebay" element={<AdminEbay eBay={eBay} batches={batches} />} />
          <Route path="admin-site-settings" element={<SiteSettings siteSettings={siteSettings} setSiteSettings={setSiteSettings} />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default App
