import React, { useEffect, useState } from 'react'
import { getStocksForPre } from '../../API'
import AdminNavigation from '../admin/AdminNavigation'
import { getPriceComicB } from '../../utils/MoneyFormats'
import { formatMoney } from '../../utils/MoneyFormats'
import './AllStockList.scss'
import { useParams } from 'react-router-dom'
import './PreOrderListCondensed.scss';

const PreOrderList: React.FC = () => {
  const [stock, setStocks] = useState<IComic[]>([])
  const { batchId } = useParams();
  const [showNotSelected, setShowNotSelected] = useState(true);

  useEffect(() => {
    fetchStocks(batchId)
  }, [batchId])

  const fetchStocks = (batchId?: string): void => {
    // console.log(`fetchStocks()`);
    getStocksForPre(batchId, 2000)
      .then(({ data: { comics } }: IComic[] | any) => setStocks(comics))
      .catch((err: Error) => console.log(err))
  }
  
  const handleFormSwitch = (): void => {
    setShowNotSelected(!showNotSelected);
  }

  // const handleSaveStock = (e: React.FormEvent, formData: IComic): void => {
  //   e.preventDefault()
  //   addStock(formData)
  //     .then(({ status, data }) => {
  //       if (status !== 201) {
  //         throw new Error('Error! Stock not saved')
  //       }
  //       setStocks(data.comics)
  //     })
  //     .catch((err) => console.log(err))
  // }

  // const handleUpdateStock = (comic: IComic): void => {
  //   // console.log(comic);
  //   updateStock(comic)
  //     .then(({ status }) => {
  //       if (status !== 200) {
  //         throw new Error('Error! Stock not updated')
  //       }
  //       fetchStocks(batchId);
  //     })
  //     .catch((err) => console.log(err))
  // }

  // const handleDeleteStock = (comic: IComic): void => {
  //   if (comic._id !== undefined) {

  //     Swal.fire({
  //       title: 'Are you sure?',
  //       text: "You won't be able to revert this!",
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#3085d6',
  //       cancelButtonColor: '#d33',
  //       confirmButtonText: 'Yes, delete it!'
  //     }).then((result) => {
  //       if (result.isConfirmed) {

  //         if (comic._id !== undefined) {
  //           deleteStock(comic._id)
  //             .then(({ status }) => {
  //               if (status !== 200) {
  //                 throw new Error('Error! Stock not updated')
  //               }
  //               fetchStocks(batchId);

  //               Swal.fire(
  //                 'Deleted!',
  //                 'Your file has been deleted.',
  //                 'success'
  //               )
  //             })
  //             .catch((err) => console.log(err))
  //           Swal.fire(
  //             'NOT Deleted!',
  //             'Your file was NOT deleted.',
  //             'error'
  //           )
  //         }
  //       }
  //     })
  //   }
  // }

  const stockSelected = stock.filter(i => i.selected);
  const stockFiltered = stock.filter(i => showNotSelected || i.selected);

  return (
    <>
      <article>
        <h1>Pre-Order Condensed {batchId} ({stock.length} unique,&nbsp;
          {stock.reduce((partialSum, comic) => partialSum + (comic.number ? comic.number : 0), 0)} total,
          ${formatMoney(stock.reduce((partialSum, comic) => partialSum + (comic.number ? comic.number * getPriceComicB(comic, comic.batch) : 0), 0))})</h1>
        <div>{stockSelected.length} Selected, {stock.length} Total&nbsp;&nbsp;•&nbsp;&nbsp;
        <span onClick={handleFormSwitch} className="link-button">{showNotSelected ? 'Hide Not Selected' : 'Show Not Selected'}</span></div>
        <br />
        <table>
          {stockFiltered && stockFiltered.map((comic: IComic) => (
            <tr>
              <td><input type="checkbox" checked={comic.selected} className="selected-checkbox" /></td>
              <td>{comic.mainDescription} {comic.variantDescription ? ' - ' + comic.variantDescription : ''}</td>
              <td>{comic.publisher}</td>
              <td>{Math.round(comic.srp + Number.EPSILON) / 100}</td>
            </tr>
          ))}
        </table>
      </article>
      <nav><AdminNavigation /></nav>
    </>
  )
}

export default PreOrderList
