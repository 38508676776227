import React, { useEffect, useState } from 'react'
import { OldBatchType } from '../../common'

type Props = {
  batches: IBatch[] | undefined,
  batchType?: OldBatchType,
  comic: IComic,
  setComic: (comic: IComic) => void,
}

export const getBatchNameWithType = (batch: IBatch) => {
  return batch.name + ' (' + batch.type + ')';
}

const BatchDropDown: React.FC<Props> = ({ batches, batchType, comic, setComic }) => {

  const [batchesFiltered, setBatchesFiltered] = useState<IBatch[]>();

  useEffect(() => {
    const newBatchesFiltered = ([...new Set<IBatch>(batches?.filter(obj => batchType === undefined || obj.type === batchType))]);
    setBatchesFiltered(newBatchesFiltered);

    // set default
    if (comic.batch && comic.batch.name !== '') {
      console.log('already set to ' + comic.batch.name);
    }
    else {
      if (batches && newBatchesFiltered && newBatchesFiltered.length > 0) {
        console.log('setting to ' + newBatchesFiltered[0].name)
        setComic({
          ...comic,
          batch: newBatchesFiltered[0],
          batchDate: newBatchesFiltered[0].publishDate,
          periodType: newBatchesFiltered[0].periodType,
          supplierType: newBatchesFiltered[0].supplierType,
        })
      }
      else {
        console.log('not setting default ' + newBatchesFiltered?.length);
      }
    }
  }, [batches]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e: React.FormEvent<HTMLSelectElement>): void => {
    console.log('e.currentTarget.value = ' + e.currentTarget.value);
    const batch = batches?.find(batch => (batch.name === e.currentTarget.value || getBatchNameWithType(batch) === e.currentTarget.value));
    console.log('batch = ' + batch?.name);
    if (batch) {
      console.log('batch2 = ' + batch?.name);
      setComic({
        ...comic,
        batch: batch,
        batchDate: batch.publishDate,
        periodType: batch.periodType,
        supplierType: batch.supplierType,
      })
    }
  }

  return (
    <>
      <select value={batchType === undefined ? getBatchNameWithType(comic.batch) : comic.batch?.name} onChange={handleChange}>
        {batchesFiltered && batchesFiltered.map((batch, key) => <option key={key}>{batchType === undefined ? getBatchNameWithType(batch) : batch.name}</option>)}
      </select>
    </>
  )
}

export default BatchDropDown