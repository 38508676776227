import React, { ChangeEvent, useRef, useState } from 'react'
import { addStock } from '../../API';
import { ItemType, PeriodType, SupplierType } from '../../common';
import { getMonthAndYearFromDiamondId, formatPublisher, formatMainDescription, formatVariantDescription } from '../../utils/OrderFormats';

type Props = BatchProps & {
  setBatch(batch: IBatch): void;
  handleCancel: () => void,
  handleFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void,
}

const BatchItemUpload: React.FC<Props> = ({ batch/*, setBatch, handleCancel, handleFormSubmit */ }) => {

  const [file, setFile] = useState<File>();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadClick = () => {
    // 👇 We redirect the click event onto the hidden input element
    inputRef.current?.click();
    // console.log('clicked');
  };

  const getPosition = (string: any, subString: any, index: any) => {
    return string.split(subString, index).join(subString).length;
  }

  const getNameSpin = (string: any) => {
    return string.indexOf(',') > 0 ? string.substring(string.indexOf(',') + 1).trim() + ' ' + string.substring(0, string.indexOf(',')).trim() : '';
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const theFile = e.target.files[0];
    setFile(e.target.files[0]);
    var reader = new FileReader();
    reader.onload = function () {
      const contents = this.result as string;
      const contentsArr = contents ? contents.split('\n') : [""];
      // console.log(contentsArr);
      contentsArr.map(line => {
        let triggerRefresh = false;
        if (line.length > 1) {
          const comicId = line.substring(0, line.indexOf('\t')).trim();
          const comicVariant = line.substring(getPosition(line, '\t', 6), getPosition(line, '\t', 7)).trim();
          const comicName = comicVariant && comicVariant.length > 0 ?
            line.substring(getPosition(line, '\t', 4), line.indexOf(comicVariant)).trim() :
            line.substring(getPosition(line, '\t', 4), getPosition(line, '\t', 5)).trim();
          const srp = parseFloat(line.substring(getPosition(line, '\t', 12), getPosition(line, '\t', 13)).trim()) * 100;
          const publisher = line.substring(getPosition(line, '\t', 13), getPosition(line, '\t', 14)).trim();
          const writer = getNameSpin(line.substring(getPosition(line, '\t', 39), getPosition(line, '\t', 40)).trim());
          const artist = getNameSpin(line.substring(getPosition(line, '\t', 40), getPosition(line, '\t', 41)).trim());
          const coverArtist = getNameSpin(line.substring(getPosition(line, '\t', 41), getPosition(line, '\t', 42)).trim());
          const monthAndYear = getMonthAndYearFromDiamondId(comicId);
          const comicNameFormatted = formatMainDescription(comicName);
          const comicVariantFormatted = formatVariantDescription(comicVariant);
          const selected = 
            publisher === 'MARVEL PRH' &&
            comicVariantFormatted.length === 0 &&
            comicNameFormatted.indexOf(' TP') === -1 &&
            comicNameFormatted.indexOf(' HC') === -1 &&
            comicNameFormatted.indexOf(' Poster') === -1 &&
            comicNameFormatted.indexOf('Marvel Previews') === -1;

          console.log(comicNameFormatted + " | " + comicVariantFormatted + " | " + selected);

          if (comicId !== 'DIAMD_NO' && comicNameFormatted.indexOf('Please Do Not Order From This Line') === -1) {
            const comic: IComic = {
              _id: '',
              // type: OldComicType.PRE,
              itemType: ItemType.COMIC,
              periodType: PeriodType.NEW,
              supplierType: SupplierType.DIAMOND,
              comicId: comicId,
              srp: srp,
              publisher: formatPublisher(publisher),
              mainDescription: comicNameFormatted,
              variantDescription: comicVariantFormatted,
              writer: writer,
              artist: artist,
              coverArtist: coverArtist,
              selected: selected,
              number: 0,
              pnp: 0,
              releaseMonth: monthAndYear.month,
              releaseYear: monthAndYear.year,
              initialQuantity: 0,
              salesQuantity: 0,
              salesValue: 0,
              batch: batch,
              images: [],
            }
            console.log(comic);
            triggerRefresh = true;
            addStock(comic)
              .then(({ status }) => {
                if (status !== 201) {
                  throw new Error('Error! Todo not saved')
                }
              })
              .catch((err) => console.log(err))
          }
        }
        if (triggerRefresh) {
          // handleTriggerRefresh();
        }
        return triggerRefresh;
      });
    };
    reader.readAsText(theFile);
  };



  return (
    <>
      <div>
        <h1 className='inline no-top-margin'>{batch.name}</h1>
        {batch.default && (<div>(default)<br /><br /></div>)}
        <div>{batch.cutOffDate}</div>
        <div>{batch.type}</div>
      </div>
      <hr />
      <div>
        Upload a file:&nbsp;

        {/* 👇 Our custom button to select and upload a file */}
        <button onClick={handleUploadClick}>
          Click to Select
        </button>

        {/* 👇 Notice the `display: hidden` on the input */}
        <input
          type="file"
          ref={inputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        {file ? (<div>Uploaded <b>{file.name}</b></div>) : ''}
      </div>
    </>
  );
}

export default BatchItemUpload