import React from "react";
import { OldBatchType } from "../../common";

type Props = {
  batchTypeFilter: string,
  setBatchTypeFilter: (batchTypeFilter: string) => void,
}

const BatchTypeFilter: React.FC<Props> = ({ batchTypeFilter, setBatchTypeFilter }) => {

  const batchTypes = [OldBatchType.BACK_ISSUE, OldBatchType.DIAMOND, OldBatchType.GAME, OldBatchType.PRE];

  return (
    <span className='link-small'>
      {batchTypes.map(c => (<>&nbsp;<button key={c} onClick={() => setBatchTypeFilter(c)} className={'link-button' + (c === batchTypeFilter ? ' bold' : '')}>{c}</button>&nbsp;•&nbsp;</>))}
      <button onClick={() => setBatchTypeFilter('all')} className={'link-button' + ('all' === batchTypeFilter ? ' bold' : '')}>All</button>
    </span>)
}

export default BatchTypeFilter;