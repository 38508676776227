import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { getAllOrders, deleteOrder, completeOrder } from '../../API'
import AdminNavigation from './AdminNavigation'
import OrderHistoryItem from './OrderHistoryItem'
import { State } from '../../App'

type Props = {
  state: State,
  setState: any,
}

const AllOrderHistoryList: React.FC<Props> = ({ state, setState }) => {
  const [orders, setOrders] = useState<IOrder[]>([])

  useEffect(() => {
    fetchOrders()
  }, [])

  const fetchOrders = (): void => {
    // console.log(`fetchOrders()`);
    getAllOrders()
      .then(({ data: { orders } }: IOrder[] | any) => setOrders(orders))
      .catch((err: Error) => console.log(err))
  }

  const handleDeleteOrder = (_id: string): void => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteOrder(_id)
          .then(({ status, data }) => {
            if (status !== 200) {
              throw new Error('Error! Order not deleted')
            }
            setOrders(data.orders)
          })
          .catch((err) => console.log(err))
      }
    });
  }

  const handleCompleteOrder = (order: IOrder): void => {
    const index = orders.indexOf(order);
    order.complete = true;
    completeOrder(order)
      .then(({ data: { order } }: IOrder[] | any) => {
        const newOrders = [...orders];
        newOrders[index] = order;
        setOrders(newOrders);
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <article>
        <h1>All Orders</h1>
        <br />
        <div className='column-centre'>
          {/* <AddOrder saveOrder={handleSaveOrder} /> */}
          {orders && orders.map((order: IOrder) => (
            <OrderHistoryItem
              key={order._id}
              deleteOrder={handleDeleteOrder}
              completeOrder={handleCompleteOrder}
              orderInput={order}
              state={state}
              setState={setState}
            />
          ))}
        </div>
      </article>
      <nav><AdminNavigation /></nav>
      <aside></aside>
    </>
  )
}

export default AllOrderHistoryList