import React, { useState } from 'react'
import './OrderHistoryItem.scss'
import { markAsPosted, postOrder, resendConfirm, resendPosted } from '../../API'
import { AUSPOST_URL } from '../../utils/UrlConsts'
import { State } from '../../App'

type Props = {
  orderInput: IOrder,
  state: State,
  setState: any,
  deleteOrder?: (_id: string) => void
  completeOrder?: (order: IOrder) => void
}

const formatAddress = (address: IAddress | undefined) => {
  if (!address) { return ''; }
  return address.givenName + ' ' + address?.familyName + ', ' + address.addressLine1 + ", " + address.suburb + ", " + address.state + ' ' + address.postalCode;
}

const OrderHistoryItem: React.FC<Props> = ({ orderInput, state, setState, deleteOrder, completeOrder }) => {

  // const [trackingNumber, setTrackingNumber] = useState('');
  const [order, setOrder] = useState(orderInput);

  const handleTrackingNumberChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const trackingNumber = e.currentTarget.value;
    setOrder({ ...order, trackingNumber: trackingNumber });
  }

  const handleLocalPostOrder = (): void => {
    postOrder(order._id, order.trackingNumber)
      .then(({ data: { order } }: IOrder[] | any) => {
        console.log(order.completedDate + ' updated');
        setOrder(order);
      })
      .catch((err: Error) => console.log(err))
  }

  const handleMarkAsPostedOrder = (): void => {
    markAsPosted(order._id)
      .then(({ data: { order } }: IOrder[] | any) => {
        console.log(order.completedDate + ' updated');
        setOrder(order);
      })
      .catch((err: Error) => console.log(err))
  }

  const handleResendConfirm = (): void => {
    resendConfirm(order._id)
      .then(({ data: { order } }: IOrder[] | any) => {
        console.log(order.completedDate + ' confirm sent');
        setOrder(order);
      })
      .catch((err: Error) => console.log(err))
  }

  const handleResendPosted = (): void => {
    resendPosted(order._id)
      .then(({ data: { order } }: IOrder[] | any) => {
        console.log(order.completedDate + ' posted sent');
        setOrder(order);
      })
      .catch((err: Error) => console.log(err))
  }

  const handleOpenCreditDialog = () => {
    setState({ 
      ...state, 
      targetUserId:order.user, 
      creditDialogOpen: true 
    });
  }

  return (
    <div className='Card'>
      <div className='Card--text'>
        <h3>
          {order.name !== '' ? order.name :
          (order.user?.givenName ? order.user?.givenName + ' ' : '') +
          (order.user?.familyName ? order.user?.familyName + ' - ' : '') +
          (order.user?.email ? order.user?.email : order.email)} - #{order.publicId}</h3>
        <div>
          Paid: {order.paid}&nbsp;&nbsp;&nbsp;&nbsp;
          Total: {order.total}&nbsp;&nbsp;&nbsp;&nbsp;
          To Pay: {order.toPay}&nbsp;&nbsp;&nbsp;&nbsp;
          P&P: {order.pnp}&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        {order.carriedForward > 0.01 ? 'Carried Forward: ' + order.carriedForward : ""}
        <br />
        <div>Created: {order.createdAt ? new Date(order.createdAt).toLocaleString('en-AU', {timeZone: "Australia/Sydney"}) : ''}</div>
        <div>{order.complete ? <span>Completed: {order.completedDate ? new Date(order.completedDate).toLocaleString('en-AU', {timeZone: "Australia/Sydney"}) : ''}</span> : <span>Updated: {order.updatedAt ? new Date(order.updatedAt).toLocaleString('en-AU', {timeZone: "Australia/Sydney"}) : ''}</span>}</div>
        {order.posted && order.postedDate && (<div>Posted: {new Date(order.postedDate).toLocaleString('en-AU', {timeZone: "Australia/Sydney"})}&nbsp;&nbsp;•&nbsp;&nbsp;
          {order.trackingNumber && <span><a href={AUSPOST_URL+order.trackingNumber} target='_blank' rel="noreferrer">{order.trackingNumber}</a></span>}</div>)}
        {(!order.posted && order.name === '') && (<div><input type='text' className="trackingNumberInput" onChange={handleTrackingNumberChange} value={order.trackingNumber} placeholder='Tracking Number'/><button className="link-button" onClick={handleLocalPostOrder}>post</button>&nbsp;&nbsp;•&nbsp;&nbsp;
        <button className="link-button" onClick={handleMarkAsPostedOrder}>mark</button></div>)}
        {order.searchTerms !== '' && (<><div>searches (old): {order.searchTerms}</div><br /></>)}
        {(order.searchHistory && order.searchHistory.length > 0) && (<><div>searches: {order.searchHistory}</div><br /></>)}
        <br />
        {order.complete && order.name === '' ?
          (<><div>Shipping: {order.shippingIsPickup ? 'Local Pickup' : formatAddress(order.shippingAddress)}</div><br /></>) : ''}
        {order.orderItems && order.orderItems.map((item: IOrderItem) => (
          <div key={item.name}>{item.quantity}x {item.name}</div>
        ))}
      </div>
      <br />
      <div className='Card--button'>
        <button className="link-button" onClick={handleResendConfirm}>resend confirm</button>
        {order.posted && (<>&nbsp;&nbsp;•&nbsp;&nbsp;<button className="link-button" onClick={handleResendPosted}>resend posted</button></>)}
        {!order.complete && completeOrder ?
          (<>&nbsp;&nbsp;•&nbsp;&nbsp;
          <button
            onClick={() => completeOrder(order)}
            className='link-button'
          >
            complete
          </button></>)
          : ''
        }
        {deleteOrder ?
          (<>&nbsp;&nbsp;•&nbsp;&nbsp;<button
            onClick={() => deleteOrder(order._id)}
            className='link-button'
          >
            delete
          </button></>) : ''}
        {order.user && (<>&nbsp;&nbsp;•&nbsp;&nbsp;<button className="link-button" onClick={handleOpenCreditDialog}>send credit</button></>)}
      </div>
      <hr />
    </div>
  )
}

export default OrderHistoryItem