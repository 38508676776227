import React from 'react'
import { getPeriodTypeName, PeriodType } from '../../common';

type Props = {
  batch: IBatch, 
  setBatch: (batch: IBatch) => void,
}

const BatchTypeDropDown: React.FC<Props> = ({batch, setBatch}) => {

  const periodTypes = [PeriodType.NEW, PeriodType.BACK_ISSUE, PeriodType.VINTAGE, PeriodType.PRE_ORDER];

  const handleChange = (e: React.FormEvent<HTMLSelectElement>): void => {
    const periodType = periodTypes?.find(periodType => periodType === e.currentTarget.value);
    if (periodType) {
      setBatch({
        ...batch,
        periodType: periodType,
      })
    }
  }

  return (
    <>
      <select className='inline auto-width' value={batch.periodType} onChange={handleChange}>
        {periodTypes && periodTypes.map((periodType, key) => <option key={key} value={periodType}>{getPeriodTypeName(periodType)}</option> )}
      </select>
    </>
  )
}

export default BatchTypeDropDown