const USD_TO_AUD = 1.5;
const MARKUP = 1.05;

export function getPriceSRPRaw(value: number) {
  const base = ((value * USD_TO_AUD * MARKUP) / 100);
  const roundup = Math.ceil(base / 0.25) * 0.25;
  const oneoff = roundup + "" === (roundup.toFixed(0)) ? roundup - 0.05 : roundup;
  return oneoff * 100;
}

export function getPriceSRP(value: number, discountPercent: number) {
  const base = ((value * USD_TO_AUD * MARKUP) / 100);
  const roundup = Math.ceil(base / 0.25) * 0.25;
  const oneoff = roundup + "" === (roundup.toFixed(0)) ? roundup - 0.05 : roundup;
  const discount = oneoff - (oneoff * (discountPercent/100))
  return discount * 100;
}

export function getPriceComicB(comic: IComic, batch: IBatch): number {
  let price = comic.priceOverride ? comic.priceOverride : getPriceSRPRaw(comic.srp);
  price = price - (price * (batch.discountPercent/100))
  return price;
}

export function getPriceComic(comic: IComic, discountPercent: number): number {
  let price = comic.priceOverride ? comic.priceOverride : getPriceSRPRaw(comic.srp);
  price = price - (price * (discountPercent/100))
  return price;
}

export function formatPriceSRP(price: number, discountPercent: number) {
  return formatMoney(getPriceSRP(price, discountPercent));
}

export function formatPriceSRPRaw(price: number) {
  return formatMoney(getPriceSRPRaw(price));
}

export function formatPriceComic(comic: IComic, discountPercent: number) {
  return formatMoney(getPriceComic(comic, discountPercent));
}

export function formatPriceOverrideComicRaw(comic: IComic) {
  return comic.priceOverride ? formatMoney(comic.priceOverride) : '';
}

export function formatMoney(price: number | undefined) {
  return price ? (price / 100).toFixed(2) : '0';
}

export function convertMoney(price: number) {
  return (price / 100).toFixed(2);
}

export function getEbayFee(price: number):number {
  // console.log( 'price3 ' + price );
  const withPostage = parseInt(price+"") + 400;
  // console.log( 'withPostage ' + withPostage );
  const fee = (withPostage * 0.10); // should be 0.135
  // console.log( 'fee ' + fee );
  // console.log(withPostage + ' ' + fee);
  return fee;
}

export const convertEbayPrice = (price: number):number => {
  // console.log( 'price2 ' + price );
  const priceAndFee = parseInt(price+"") + getEbayFee(price);
  // console.log( 'priceAndFee ' + priceAndFee );
  const multiple = priceAndFee < 500 ? 25 : priceAndFee < 2000 ? 50 : priceAndFee < 10000 ? 100 : 200;
  const round = Math.ceil(priceAndFee / multiple) * multiple;
  // console.log( 'round ' + round );
  const final = round < 100 ? 100 : round;
  // console.log( 'final ' + final );
  return final;
}
export function formatEbayPrice(price: number) {
  return formatMoney(convertEbayPrice(price));
}
export function convertEbayPriceComic(comic: IComic, discountPercentage: number) {
  const price = getPriceComic(comic, discountPercentage);
  // console.log( 'price ' + price );
  return convertEbayPrice(price);
}
export const formatEbayPriceComic = (comic: IComic, discountPercentage: number) => {
  // console.log( 'comic.priceOverride ' + comic.priceOverride );
  // console.log( 'convertEbayPriceComic(comic) ' + convertEbayPriceComic(comic) );
  return formatMoney(convertEbayPriceComic(comic, discountPercentage));
}
