import { createSlice } from '@reduxjs/toolkit';

export const stockSlice = createSlice({
  name: 'stock',
  initialState: {
    list: [{ id: 1, title: 'Pulp Fiction' }, { id: 2, title: 'Rambo' }]
  },
  reducers: {

  }
})

export default stockSlice.reducer;