import React from 'react';
import AddGenericItem from './AddGenericItem';
import { ItemType, OldBatchType, PeriodType } from '../../common';

type Props = {
  formComic: IComic,
  setFormComic: (formComic: IComic) => void,
  batches: IBatch[] | undefined,
  handleCancel: () => void,
  handleFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void,
}

const AddPreOrderItem: React.FC<Props> = ({ formComic, setFormComic, batches, handleCancel, handleFormSubmit }) => {

  return (
    <AddGenericItem
      formComic={formComic} setFormComic={setFormComic} batches={batches} handleCancel={handleCancel} handleFormSubmit={handleFormSubmit}

      itemType={ItemType.COMIC}
      periodType={PeriodType.PRE_ORDER}
      batchType={OldBatchType.PRE}
      showSRP={true}
      showImages={false}
      showEbayPricing={true}
      showMonth={true}
      showYear={true}
    />
  );
}

export default AddPreOrderItem