import React from 'react'
import { State } from '../../App';

type Props = UserProps & {
  state: State,
  setState: any,
}

const UserItem: React.FC<Props> = ({ state, setState, user }) => {
  const checkUser: string = ''/* user.status ? `line-through` */;
  
  const handleOpenCreditDialog = () => {
    setState({ 
      ...state, 
      targetUserId:user._id, 
      creditDialogOpen: true 
    });
  }

  return (
    <div className='Card'>
      <div className='Card--text'>
        <h1 className={checkUser}>{user.givenName} {user.familyName}</h1>
        <span className={checkUser}>{user.email}</span>
      </div>
      <br/>
      <button className="link-button" onClick={handleOpenCreditDialog}>send credit</button>
      <hr/> 
    </div>
  )
}

export default UserItem
