import React, { useEffect, useState } from 'react'
// import AddStock from './AddStock'
import { checkImages, getStocks, updateStock } from '../../API'
import AdminNavigation from '../admin/AdminNavigation'
import StockImagesItem from './StockImagesItem'

const StockImagesList: React.FC = () => {
  const [stock, setStocks] = useState<IComic[]>([])

  useEffect(() => {
    fetchStocks()
  }, [])

  const fetchStocks = (): void => {
    console.log(`fetchStocks()`);
    getStocks()
      .then(({ data: { comics } }: IComic[] | any) => setStocks(comics))
      .catch((err: Error) => console.log(err))
  }

  // const handleSaveStock = (e: React.FormEvent, formData: IComic): void => {
  //   e.preventDefault()
  //   addStock(formData)
  //     .then(({ status, data }) => {
  //       if (status !== 201) {
  //         throw new Error('Error! Stock not saved')
  //       }
  //       setStocks(data.comics)
  //     })
  //     .catch((err) => console.log(err))
  // }

  const handleUpdateStock = (comic: IComic): void => {
    updateStock(comic)
      .then(({ status, data }) => {
        if (status !== 200) {
          throw new Error('Error! Stock not updated')
        }
        setStocks(data.comics)
      })
      .catch((err) => console.log(err))
  }

  const handleCheckImagesClick = () => {
    checkImages()
      .then(({ status, data }) => {
        if (status !== 200) {
          throw new Error('Error! Stock not updated')
        }
        setStocks(data.comics);
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <article>
        <h1>Stock Images needed ({stock.length})</h1> 
          <button onClick={handleCheckImagesClick}>Check Stock</button>
          {/* <AddStock saveStock={handleSaveStock} /> */}
          {stock && stock.map((comic: IComic) => (
            <StockImagesItem
              key={comic._id}
              updateStock={handleUpdateStock}
              comic={comic}
            />
          ))}
      </article>
      <nav><AdminNavigation/></nav>
      <aside></aside>
    </>
  )
}

export default StockImagesList
