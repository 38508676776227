import React from 'react'

type Props = {
  feedback: IFeedback,
  // updateFeedback: (feedback: IFeedback) => void,
  // deleteFeedback: (_id: string) => void,
}

const Feedback: React.FC<Props> = ({ feedback }) => {
  return (
    <div className='Card'>
      <hr/>
      <div className='Card--text'>
        <h1>{feedback.email}</h1>
        <div>{feedback.description}</div>
        <div>{feedback.createdAt ? new Date(feedback.createdAt).toLocaleString('en-AU', {timeZone: "Australia/Sydney"}) : ''}</div>        
      </div>
    </div>
  )
}

export default Feedback
