import React from 'react'
import { formatMoney } from '../../utils/MoneyFormats'
import { AUSPOST_URL } from '../../utils/UrlConsts'
import './AccountOrderHistoryItem.scss';
import { getOrderItemUrl } from '../../utils/ImageHandler';
import { ItemType } from '../../common';
import AdminSearchStockDetails from '../admin/AdminSearchStockDetails';

type Props = {
  order?: IOrder
  adminInfo?: boolean
}

const AccountOrderHistoryItem: React.FC<Props> = ({ order, adminInfo }) => {

  return (
    <div className='Card'>
      <div className='Card--text'>
        {order &&
          (
            <>
              <h3 className='margin-bottom-less'>Order Completed on {order.completedDate ? new Date(order.completedDate).toLocaleString('en-AU', { timeZone: "Australia/Sydney" }) : ''}</h3>

              {order.posted && order.postedDate && (<div className='margin-bottom-less'>Posted: {new Date(order.postedDate).toLocaleString('en-AU', { timeZone: "Australia/Sydney" })}
                {order.trackingNumber && <span>&nbsp;&nbsp;•&nbsp;&nbsp;<a href={AUSPOST_URL + order.trackingNumber} target='_blank' rel="noreferrer">{order.trackingNumber}</a></span>}</div>)}

              {adminInfo && <AdminSearchStockDetails order={order} />}

              {order.orderItems && order.orderItems.map((orderItem: IOrderItem) => (
                <div className="order-items">
                  <div className="order-item-preview-holder">
                    <img className={orderItem.itemType && orderItem.itemType === ItemType.GAME ? 'comic-item-preview-cover-game' : 'comic-item-preview-cover'} alt='cover' src={getOrderItemUrl(orderItem)} />
                  </div>
                  <div className="order-item-details">
                    {orderItem.quantity}x <b>{orderItem.name}</b>&nbsp;at ${formatMoney(orderItem.price)} ea.
                  </div>
                </div>
              ))}
              <br />
              Item Total: ${formatMoney(order.total)}<br />
              Postage: ${formatMoney(order.pnp)}<br />
              Total Paid: ${formatMoney(order.toPay)}<br />
              {order.carriedForward > 0.01 ? (<>Carried Forward: {order.carriedForward}<br /></>) : ""}

            </>)}
      </div>
      <hr />
    </div>
  )
}

export default AccountOrderHistoryItem