import React, { Fragment, useState } from 'react';
import './Autocomplete.scss';

type Props = {
  value:string,
  setValue: (publisher: string) => void;
  placeholder?: string
  suggestions: string[];
}

//class Autocomplete extends Component {

const Autocomplete: React.FC<Props> = ({ value, setValue, placeholder, suggestions }) => {

  const [state, setState] = useState({
    // The active selection's index
    activeSuggestion: 0,
    // The suggestions that match the user's input
    filteredSuggestions: [''],
    // Whether or not the suggestion list is shown
    showSuggestions: false,
    // What the user has entered
    userInput: value
  });

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      (suggestion: string) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setState({
      activeSuggestion: 0,
      filteredSuggestions: filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
    setValue(e.currentTarget.value);
  };

  const onClick = (e: any) => {
    console.log(e);
    setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
    setValue(e.currentTarget.innerText);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { activeSuggestion, filteredSuggestions } = state;
    // User pressed the enter key
    if (e.keyCode === 13) {
      setState({
        activeSuggestion: 0,
        showSuggestions: false,
        filteredSuggestions: [],
        userInput: filteredSuggestions[activeSuggestion]
      });
      setValue(filteredSuggestions[activeSuggestion]);
      e.preventDefault();
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      setState({ ...state, activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      setState({ ...state, activeSuggestion: activeSuggestion + 1 });
    }
  };

  return (
    <Fragment>
      <input
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={state.userInput}
      />
      <div className="suggestions-anchor">
        {state.showSuggestions && state.userInput && state.filteredSuggestions.length ?
          (
            <div className="suggestions">
              {state.filteredSuggestions.map((suggestion, index) => {
                let className;

                // Flag the active suggestion with a class
                if (index === state.activeSuggestion) {
                  className = "suggestion-active";
                }

                // onClick={onClick}
                return (
                  <div className={'suggestion-item ' + className} key={suggestion} onClick={(e) => onClick(e)} >
                    {suggestion}
                  </div>
                );
              })}
            </div>
          ) : (
            <></>
          )}
      </div>
    </Fragment>
  );
}

export default Autocomplete;
