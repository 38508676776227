import React from 'react'
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import { convertMoney, formatMoney } from '../../utils/MoneyFormats';

type Props = {
  order: any,
  shippingAddress: IAddress,
  billingAddress: IAddress,
  handleResponseReceived: (token: any, verifiedBuyer: any) => void,
  handleCompleteOrder: () => void,
  handleChangeShippingAddress: () => void,
  handleChangeBillingAddress: () => void,
}

const SquarePaymentForm: React.FC<Props> = ({ order, shippingAddress, billingAddress, handleResponseReceived, handleCompleteOrder, handleChangeShippingAddress, handleChangeBillingAddress }) => {

  return (
    <>
      <h3 className='margin-bottom-less'>Address Summary</h3>
      <div className='address-summary'>
        <b>Shipping</b>
        <div className='address-details'>{order.shippingIsPickup ? 'Local pick at Brisbane' : (<>{shippingAddress.addressLine1}, {shippingAddress.state} {shippingAddress.postalCode}</>)}</div>
        <button className='link-button' onClick={handleChangeShippingAddress}>change</button>
        <div className='address-divider'></div>
        <b>Billing</b>
        <div className='address-details'>{order.shippingIsSameAsBilling ? 'Same as Shipping' : (<>{billingAddress.addressLine1}, {billingAddress.state} {billingAddress.postalCode}</>)}</div>
        <button className='link-button' onClick={handleChangeBillingAddress}>change</button>
      </div>
      <br />
      <h3 className='margin-bottom-less'>Order Summary</h3>
      <div className='address-summary'>
        <div>
          {order.creditFrom > 0.01 ? (<div className='payment-summary-extra'>Previous Credit ${formatMoney(order.creditFrom)}</div>) : ""}
          <div className='padding-top-small'>Total {order.itemCount > 1 ? "x" + order.itemCount + " items at" : ""} ${formatMoney(order.total)}</div>
          <div className='padding-top-small'>Postage ${formatMoney(order.pnp)}</div>
          {order.paid > 0 ? (<div className='padding-top-small'>paid ${formatMoney(order.paid)}</div>) : ""}
          {order.toPay > 0.01 ? (<div className='padding-top-small'>To pay <b>${formatMoney(order.toPay)}</b></div>) : ""}
          {order.toPay <= 0.01 && order.total >= 0.01 ? (<div className='padding-top-small'><b>All paid!</b></div>) : ""}
          {order.creditForward > 0.01 && order.total >= 0.01 ? (<div className='padding-top-small'>Credit ${formatMoney(order.creditForward)}</div>) : ""}
        </div>
      </div>
      <br />
      {order.toPay <= 0.01 && order.total >= 0.01 ?
        (<div className='payment-dialog-button-container'>
          <button
            onClick={() => handleCompleteOrder()}
            className='standard-button'
          >
            Complete
          </button>
        </div>) :
        (<>
          <h3 className='margin-bottom-less'>Payments by Square</h3>
          <PaymentForm
            /**
             * Identifies the calling form with a verified application ID generated from
             * the Square Application Dashboard.
             */
            applicationId={process.env.REACT_APP_APPLICATION_ID as string}

            /**
             * Invoked when payment form receives the result of a tokenize generation
             * request. The result will be a valid credit card or wallet token, or an error.
             */
            cardTokenizeResponseReceived={handleResponseReceived}

            /**
             * This function enable the Strong Customer Authentication (SCA) flow
             *
             * We strongly recommend use this function to verify the buyer and reduce
             * the chance of fraudulent transactions.
             */
            createVerificationDetails={() => ({
              amount: convertMoney(order.toPay),
              /* collected from the buyer */
              billingContact: {
                addressLines: [billingAddress.addressLine1],
                familyName: billingAddress.familyName,
                givenName: billingAddress.givenName,
                countryCode: 'AU',
                suburb: billingAddress.suburb,
                state: billingAddress.state,
                postalCode: billingAddress.postalCode,
              },
              currencyCode: 'AUD',
              intent: 'CHARGE',
            })}

            /**
             * Identifies the location of the merchant that is taking the payment.
             * Obtained from the Square Application Dashboard - Locations tab.
             */
            locationId={process.env.REACT_APP_LOCATION_ID as string}
          >
            <CreditCard>Pay ${formatMoney(order.toPay)}</CreditCard>
          </PaymentForm>
        </>)}
    </>
  )
}

export default SquarePaymentForm;
