import React from 'react'
import BatchTypeDropDown from './BatchDropDownType';
import BatchDropDownItemType from './BatchDropDownItemType';
import BatchDropDownPeriodType from './BatchDropDownPeriodType';
import BatchDropDownSupplierType from './BatchDropDownSupplierType';

type Props = BatchProps & {
  setBatch(batch: IBatch): void;
  handleCancel: () => void,
  handleFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void,
}

export const formatDateForField = ( theDate: any) => {
  return theDate ? new Date(theDate).toISOString().slice(0, 10) : theDate;
}

const BatchItem: React.FC<Props> = ({ batch, setBatch, handleCancel, handleFormSubmit }) => {

  const handleForm = (e: React.FormEvent<HTMLInputElement>): void => {
    setBatch({
      ...batch,
      [e.currentTarget.id]: e.currentTarget.value,
    })
  }

  return (
    <>
      <form className='comic-update' onSubmit={handleFormSubmit}>
        <div>
          <div>
            <input type='text' className='inline' id='name' onChange={handleForm} value={batch.name} placeholder='Batch Name' />
            {batch.default && (<div>(default)<br /><br /></div>)}
            <div>{batch.cutOffDate}</div>
            <BatchDropDownItemType batch={batch} setBatch={setBatch} />&nbsp;
            <BatchDropDownPeriodType batch={batch} setBatch={setBatch} />&nbsp;
            <BatchDropDownSupplierType batch={batch} setBatch={setBatch} />&nbsp;
            <BatchTypeDropDown batch={batch} setBatch={setBatch} />
            <input className='auto-width' type="date" id="publishDate" onChange={handleForm} value={formatDateForField(batch.publishDate)} />
            <div><input type='number' className='inline auto-width' id='discountPercent' onChange={handleForm} value={batch.discountPercent} placeholder='Disc. %' />&nbsp;%</div>
          </div>
        </div>
        <br />
        <div>
          <button className="link-button" onClick={() => handleCancel()} type='button'>cancel</button>&nbsp;&nbsp;•&nbsp;&nbsp;
          <button className="link-button" type="submit">save</button>
          {/* <EbayControls comic={formComic} setComic={setFormComic} setResult={setResult}/>
          {result !== '' ? (<div>{result}</div>) : ''} */}
        </div>
      </form>
    </>);
}

export default BatchItem;