import React from "react";
import { ItemType, PeriodType } from "../../common";

type Props = {
  batches: IBatch[],
  itemType: ItemType,
  periodType?: PeriodType,
  batchFilter: string,
  setBatchFilter: (batchFilter: string) => void,
}

const BatchFilter: React.FC<Props> = ({ batches, itemType, periodType, batchFilter, setBatchFilter }) => {

  const batchesFiltered = [...new Set<IBatch>(batches?.filter(obj => matchBatchToItemAndPeriod(obj, itemType, periodType) ))];

  return (
    <span className='link-small'>
      {batchesFiltered.map(c => (<span key={c.name}>&nbsp;<button onClick={() => setBatchFilter(c.name)} className={'link-button' + (c.name === batchFilter ? ' bold' : '')}>{c.name}</button>&nbsp;•&nbsp;</span>))}
      <button onClick={() => setBatchFilter('all')} className={'link-button' + ('all' === batchFilter ? ' bold' : '')}>All</button>
    </span>)
}

export default BatchFilter;

export const matchBatchToItemAndPeriod = (batch:IBatch, itemType: ItemType, periodType?: PeriodType): boolean => {

  // console.log(batch.name + ' ' + batch.itemType + ' ' + batch.periodType + ' ' + itemType + ' ' + periodType);

  return (batch.itemType === itemType) && (periodType === undefined || (batch.periodType === periodType));
}
