import React, { useState } from 'react'
import { getAdminFromStorage, getUserFromStorage } from '../../utils/UserHandler';
import AddressAutocomplete from './AddressAutocomplete';

type ShippingAddressProps = {
  order: IOrder,
  setOrder: (order: IOrder) => void,
  libraries: string[],
  handleNext: () => void,
  handleLogin: () => void;
}

const ShippingAddress: React.FC<ShippingAddressProps> = ({ order, setOrder, libraries, handleNext, handleLogin }) => {

  const [shippingIsPickup, setShippingIsPickup] = useState(order.shippingIsPickup);

  const user = getUserFromStorage();

  const [shippingAddress, setShippingAddress] = useState<IAddress>(order.shippingAddress ? order.shippingAddress : {
    familyName: user.familyName,
    givenName: user.givenName,
    addressLine1: "",
    // addressLine2: "",
    countryCode: "AUD",
    suburb: "",
    state: "",
    postalCode: "",
  })

  const [formDataValid, setFormDataValid] = useState({
    emailValid: true,
    familyNameValid: true,
    givenNameValid: true,
    addressLine1Valid: true,
    suburbValid: true,
    stateValid: true,
    postalCodeValid: true,
  })

  const handleEmailForm = (e: React.FormEvent<HTMLInputElement>): void => {
    setOrder({
      ...order,
      email: e.currentTarget.value
    });

    setFormDataValid({
      ...formDataValid,
      emailValid: (e.currentTarget.value.length > 0),
    })
  }

  const handleForm = (e: React.FormEvent<HTMLInputElement>): void => {

    const newShippingAddress = {
      ...shippingAddress,
      [e.currentTarget.id]: e.currentTarget.value,
    };

    setShippingAddress(newShippingAddress);
    setOrder({
      ...order,
      shippingAddress: newShippingAddress
    });

    setFormDataValid({
      ...formDataValid,
      [e.currentTarget.id + 'Valid']: (e.currentTarget.value.length > 0),
    })
  }

  const checkField = (e: React.FormEvent<HTMLInputElement>): void => {
    setFormDataValid({
      ...formDataValid,
      [e.currentTarget.id + 'Valid']: (e.currentTarget.value.length > 0),
    })
  }

  const handleIsPickupChange = () => {
    const newValue = !shippingIsPickup;
    // console.log('shippingIsPickup ' + newValue);
    setShippingIsPickup(newValue);
    setOrder({
      ...order,
      shippingIsPickup: newValue
    });
  }

  const isFormValid = () => {
    const isFormValid =
      shippingIsPickup ||
      (formDataValid.emailValid &&
        formDataValid.familyNameValid &&
        formDataValid.givenNameValid &&
        formDataValid.addressLine1Valid &&
        formDataValid.suburbValid &&
        formDataValid.stateValid &&
        formDataValid.postalCodeValid);
    return isFormValid;
  }

  const lastCheck = () => {
    setFormDataValid({
      emailValid: (getUserFromStorage()._id !== '' || (order.email ? order.email.length > 0 : false)),
      familyNameValid: (shippingAddress.familyName ? shippingAddress.familyName.length > 0 : false),
      givenNameValid: (shippingAddress.givenName ? shippingAddress.givenName.length > 0 : false),
      addressLine1Valid: (shippingAddress.addressLine1 ? shippingAddress.addressLine1.length > 0 : false),
      suburbValid: (shippingAddress.suburb ? shippingAddress.suburb.length > 0 : false),
      stateValid: (shippingAddress.state ? shippingAddress.state.length > 0 : false),
      postalCodeValid: (shippingAddress.postalCode ? shippingAddress.postalCode.length > 0 : false),
    });

    // manual check as react is shit.

    const isValid = shippingIsPickup ||
      ((getUserFromStorage()._id !== '' || (order.email ? order.email.length > 0 : false)) &&
        shippingAddress.familyName !== undefined && shippingAddress.familyName.length > 0 &&
        shippingAddress.givenName !== undefined && shippingAddress.givenName.length > 0 &&
        shippingAddress.addressLine1 !== undefined && shippingAddress.addressLine1.length > 0 &&
        shippingAddress.suburb !== undefined && shippingAddress.suburb.length > 0 &&
        shippingAddress.state !== undefined && shippingAddress.state.length > 0 &&
        shippingAddress.postalCode !== undefined && shippingAddress.postalCode.length > 0);

    // console.log(isValid);

    if (isValid) {
      setOrder({
        ...order,
        shippingAddress: shippingAddress
      });

      handleNext();
    }
  }

  const handlePlaceSelected = (place: google.maps.places.PlaceResult | undefined) => {
    
    if (place) {

      if (!place.vicinity !== undefined && place.formatted_address !== undefined) {

        const subber = place.vicinity && place.formatted_address ? place.formatted_address?.substring(place.formatted_address?.indexOf(place.vicinity)+place.vicinity.length).trim() : '';

        const addressLine1 = place.name ? place.name : '';
        const suburb = place.vicinity ? place.vicinity : '';
        const state = subber.substring(0, subber.indexOf(' '));
        const postalCode = subber.substring(subber.indexOf(state)+state.length, subber.indexOf(','));

        setShippingAddress({
          ...shippingAddress,
          addressLine1: addressLine1,
          suburb: suburb,
          state: state,
          postalCode: postalCode,
        })

        setFormDataValid({
          ...formDataValid,
          addressLine1Valid: (addressLine1 ? addressLine1.length > 0 : false),
          suburbValid: (suburb ? suburb.length > 0 : false),
          stateValid: (state ? state.length > 0 : false),
          postalCodeValid: (postalCode ? postalCode.length > 0 : false),
        });
      }
    }
  }

  return (
    <div>
      <div>
        <div className='column-centre'>
          <div>
            <h3>Shipping Address</h3>
          </div>
          <div className='Form'>
            <div className='addressContainer'>
              <div>
                {getAdminFromStorage() || getUserFromStorage().localPickup ?
                  (<><input type='checkbox' id='shippingIsPickup' checked={shippingIsPickup} onChange={handleIsPickupChange} /><label htmlFor='shippingIsPickup' className='checkbox-label'>Local Pickup in <b>Brisbane</b></label><br /></>)
                  : ""}

                {getUserFromStorage()._id === '' ?
                  (<div className="emailContainer">
                    <div className="already-account">Already have an account? <button className='link-button' onClick={handleLogin}>Log in</button></div>
                    <label htmlFor='email' className='block'>Email</label>
                    <input onChange={handleEmailForm} onBlur={checkField} type='email' id='givenName' disabled={order.shippingIsPickup} value={order.email} className={formDataValid.emailValid ? '' : 'invalid'} />
                  </div>) : ''}

                <div className="nameContainer">
                  <div className="givenNameContainer">
                    <label htmlFor='givenName' className='block'>Given Name</label>
                    <input onChange={handleForm} onBlur={checkField} type='text' id='givenName' disabled={order.shippingIsPickup} value={shippingAddress.givenName} className={formDataValid.givenNameValid ? '' : 'invalid'} />
                  </div>

                  <div className="familyNameContainer">
                    <label htmlFor='familyName' className='block'>Family Name</label>
                    <input onChange={handleForm} onBlur={checkField} type='text' id='familyName' disabled={order.shippingIsPickup} value={shippingAddress.familyName} className={formDataValid.familyNameValid ? '' : 'invalid'} />
                  </div>
                </div>
                
                {! order.shippingIsPickup ?
                (<div className="addressContainer">
                  <label htmlFor='addressSearch'>Search Address</label>
                  <AddressAutocomplete onPlaceSelected={handlePlaceSelected} libraries={libraries} />
                </div>) : ''}

                <div className="addressContainer">
                  <label htmlFor='addressLine1'>Address</label>
                  <input onChange={handleForm} onBlur={checkField} type='text' id='addressLine1' disabled={order.shippingIsPickup} value={shippingAddress.addressLine1} className={formDataValid.addressLine1Valid ? '' : 'invalid'} />
                </div>

                <div className="addressDetailsContainer">
                  <div className="suburbContainer">
                    <label htmlFor='suburb'>Suburb</label>
                    <input onChange={handleForm} onBlur={checkField} type='text' id='suburb' disabled={order.shippingIsPickup} value={shippingAddress.suburb} className={formDataValid.suburbValid ? '' : 'invalid'} />
                  </div>

                  <div className="stateContainer">
                    <label htmlFor='state'>State</label>
                    <input onChange={handleForm} onBlur={checkField} type='text' id='state' disabled={order.shippingIsPickup} value={shippingAddress.state} className={formDataValid.stateValid ? '' : 'invalid'} />
                  </div>

                  <div className="postalCodeContainer">
                    <label htmlFor='postalCode'>Postal Code</label>
                    <input onChange={handleForm} onBlur={checkField} type='text' id='postalCode' disabled={order.shippingIsPickup} value={shippingAddress.postalCode} className={formDataValid.postalCodeValid ? '' : 'invalid'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className='payment-dialog-button-container'>
        <button
          onClick={() => lastCheck()}
          className='standard-button'
          disabled={!isFormValid()}
        >
          Continue to Billing Address
        </button>
      </div>
    </div>
  )
}


export default ShippingAddress