import React, { useEffect, useState } from 'react'
import AuditHistoryItem from './AuditHistoryItem'
import { getAuditHistory } from '../../API'
import AdminNavigation from '../admin/AdminNavigation'

const AuditHistoryList: React.FC = () => {
  const [audits, setAudits] = useState<IAudit[]>([])

  useEffect(() => {
    fetchAuditHistory()
  }, [])

  const fetchAuditHistory = (): void => {
    // console.log(`fetchOrders()`);
    getAuditHistory()
      .then(({ data: { audits } }: IOrder[] | any) => setAudits(audits))
      .catch((err: Error) => console.log(err))
  }

  return (
    <>
      <article>
        <h1>Audit History</h1>
        <div className='column-centre'>
          {audits && audits.map((audit: IAudit) => (
            <AuditHistoryItem
              key={audit._id}
              audit={audit}
            />
          ))}
        </div>
      </article>
      <nav><AdminNavigation/></nav>
      <aside></aside>
    </>
  )
}

export default AuditHistoryList