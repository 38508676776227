import React, { useState } from 'react'
import { login } from '../../API'
import swal from 'sweetalert2';
import { getTempToken, setUserInStorage } from '../../utils/UserHandler';
import { handleGenericError } from '../../utils/ErrorHandler';

const Login: React.FC = () => {

  const [formData, setFormData] = useState<ILogin>({
    email: '',
    password: '',
    tempToken: getTempToken(),
  })

  const handleForm = (e: React.FormEvent<HTMLInputElement>): void => {
    setFormData({
      ...formData,
      [e.currentTarget.id]: e.currentTarget.value,
    })
  }

  const handleLogin = (e: React.FormEvent): void => {
    e.preventDefault()
    login(formData)
      .then(({ status, data }) => {
        if (status === 200) {
          setUserInStorage(data.user, data.authorisationKey);
          swal.fire("Success", data.message, "success").then(() => {
            window.location.reload();
          });
        }
        else {
          swal.fire("Fail", data.message, "error");
        }
      })
      .catch((data) => {
        handleGenericError(data.message ? data.errorMessage : "unknown error");
      })
  }

  return (
    <div className='model-container'>
      <div className='modal-login'>
        <form className='Form' onSubmit={(e) => handleLogin(e)}>
          <div className='form-rows'>
            <div>
              <label htmlFor='email'>Email</label>
              <input onChange={handleForm} type='email' id='email' />
            </div>
            <div>
              <label htmlFor='password'>Password</label>
              <input onChange={handleForm} type='password' id='password' />
            </div>
            <div className='model-button-container'>
              <button className='standard-button' disabled={formData === undefined ? true : false} >Login</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
