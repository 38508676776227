import React, { useRef } from 'react';
import './SearchHistory.scss'
import { useOutsideAlerter } from './general/OutsideAlerter';

type Props = {
  order: IOrder,
  handleSearchHistory: (searchTerm: string) => void,
  handleClearSearchHistory: () => void,
  outsideSearchClick: () => void,
}

const searchTerms = [
  { name: 'Marvel', image: 'marvel-comics.svg' },
  { name: 'Image Comics', image: 'image-comics.svg' },
  { name: 'DC Comics', image: 'dc-comics.svg' },
  { name: 'Spider-Man', image: 'spider-man.svg' },
  { name: 'Batman', image: 'batman.svg' },
  { name: 'X-Men', image: 'x-men.svg' }
];

const SearchHistory: React.FC<Props> = ({ order, handleSearchHistory, handleClearSearchHistory, outsideSearchClick }) => {

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, outsideSearchClick);

  return (
    <>
      <div ref={wrapperRef} className='search-history-wrapper'>
        <div className='search-history'>
          <div className='search-header'>
            Recent
            {order.searchHistory.length > 0 && <span className='clear-search-header' onClick={() => handleClearSearchHistory()}>Clear</span>}
          </div>
          {order.searchHistory.map((term, idx) => (
            <div className="search-term" key={idx} onClick={() => handleSearchHistory(term)}>
              {term}
            </div>
          ))}
          {order.searchHistory.length === 0 && <div className="no-searches">No recent searches...</div>}
          <div className='search-header-2'>Common</div>
          <div className="icons-wrapper">
            {searchTerms.map((term, idx) => (
              <div className="search-icon-wrapper" key={idx} onClick={() => handleSearchHistory(term.name)}>
                <img className="search-icon" src={'/img/logos/' + term.image} title={term.name} alt={'search ' + term.name} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchHistory;