import React from 'react';
import AddGenericItem from './AddGenericItem';
import { ItemType, OldBatchType, PeriodType } from '../../common';

type Props = {
  formComic: IComic,
  setFormComic: (formComic: IComic) => void,
  batches: IBatch[] | undefined,
  handleCancel: () => void,
  handleFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void,
}

const AddBackIssueItem: React.FC<Props> = ({ formComic, setFormComic, batches, handleCancel, handleFormSubmit }) => {

  return (
    <AddGenericItem
      formComic={formComic} setFormComic={setFormComic} batches={batches} handleCancel={handleCancel} handleFormSubmit={handleFormSubmit}

      itemType={ItemType.COMIC}
      periodType={PeriodType.BACK_ISSUE}
      batchType={OldBatchType.BACK_ISSUE}
      showSRP={false}
      showImages={true}
      showEbayPricing={true}
      showMonth={false}
      showYear={true}
    />
  );
}

export default AddBackIssueItem