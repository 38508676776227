import React from 'react'

type Props = {
  order:IOrder,
  ebayInfo?:IEbayInfo,
}

const AdminSearchEbayDetails: React.FC<Props> = ({ order, ebayInfo }) => {
  
  console.log(order);

  return (
    ebayInfo ? 
    <>
      <div>Ebay Info</div>
      <div>Listing Id: <a href={'https://www.ebay.com.au/itm/' + ebayInfo.listingId} target="_blank" rel="noreferrer">{ebayInfo.listingId}</a> </div>
      <br/>
    </>
    : <></>
  );
}

export default AdminSearchEbayDetails

