import React, { useEffect, useState } from 'react'
import { getOrders } from '../../API'
import AccountNavigation from './AccountNavigation'
import AccountOrderHistoryItem from './AccountOrderHistoryItem'

const AccountOrderHistoryList: React.FC = () => {
  const [orders, setOrders] = useState<IOrder[]>([])

  useEffect(() => {
    fetchOrders()
  }, [])

  const fetchOrders = (): void => {
    // console.log(`fetchOrders()`);
    getOrders()
      .then(({ data: { orders } }: IOrder[] | any) => setOrders(orders))
      .catch((err: Error) => console.log(err))
  }

  return (
    <>
      <article>
        <h1>Your Orders</h1>
        <br />
        <div className='column-centre'>
          {/* <AddOrder saveOrder={handleSaveOrder} /> */}
          {orders && orders.map((order: IOrder) => (
            <AccountOrderHistoryItem
              key={order._id}
              order={order}
            />
          ))}
        </div>
      </article>
      <nav><AccountNavigation /></nav>
      <aside></aside>
    </>
  )
}

export default AccountOrderHistoryList