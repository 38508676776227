import React, { useState } from 'react';

const { LoadScript, Autocomplete } = require('@react-google-maps/api');

interface AddressAutocompleteProps {
  onPlaceSelected: (place: google.maps.places.PlaceResult | undefined) => void;
  libraries: string[];
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({ onPlaceSelected, libraries }) => {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();

  const handleLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const handlePlaceSelect = (place: google.maps.places.PlaceResult | undefined) => {
    onPlaceSelected(place);
  };

  return (
    <>
      <LoadScript
        googleMapsApiKey={`AIzaSyCrsreTTqqNEiKe_76Tq3m9k_xPbPLrrXc`} // TODO externalise
        libraries={libraries}
      >
        <Autocomplete
          onLoad={handleLoad}
          onPlaceChanged={() => handlePlaceSelect(autocomplete?.getPlace())}
          options={{
            componentRestrictions: { country: 'AU' },
          }}>

          <input type="text" placeholder="Enter an address to search" />
        </Autocomplete>
      </LoadScript>
    </>
  );
};

export default AddressAutocomplete;