import { ItemType } from "../common";

export const PNP_FIRST = 400;
export const PNP_STANDARD = 100;

export type StateType = { payment: number; pnp: number, total: number; items: number; paid: number; toPay: number; creditFrom: number; creditForward: number; };

export type PostageBand = {
  name: string,
  low: number,
  high: number,
  site: number,
  ebay: number,
  tracked: boolean,
}

export const Untracked1to2Comics = 520;
export const Tracked1to4Comics = 950;
export const Tracked5Comics = 1300;
export const Tracked6to10Comics = 1750;
export const Tracked11to30Comics = 2200;
export const Tracked31to50Comics = 2650;

const POSTAGE_BANDS: PostageBand[] = [
  {name: 'None',           low:0, high:0,   site: 0,    ebay: 0,    tracked: false },
  {name: 'Letter',         low:1, high:2,   site: 450,  ebay: Untracked1to2Comics,  tracked: false },
  {name: 'Large Letter',   low:3, high:4,   site: 850,  ebay: Tracked1to4Comics,  tracked: false },
  {name: 'Tracked Letter', low:1, high:2,   site: 850,  ebay: Tracked1to4Comics,  tracked: true },
  {name: 'Small Parcel',   low:3, high:4,   site: 850,  ebay: Tracked1to4Comics,  tracked: true },
  {name: 'Small Parcel',   low:5, high:5,   site: 1100, ebay: Tracked5Comics, tracked: true },
  {name: 'Parcel',         low:6, high:10,  site: 1500, ebay: Tracked6to10Comics, tracked: true },
  {name: 'Parcel',         low:11, high:30, site: 1900, ebay: Tracked11to30Comics, tracked: true },
  {name: 'Parcel',         low:31, high:50, site: 2300, ebay: Tracked31to50Comics, tracked: true },
  {name: 'Parcel',         low:51, high:60, site: 2400, ebay: 2850, tracked: true },
  {name: 'Parcel',         low:61, high:70, site: 2550, ebay: 3050, tracked: true },
  {name: 'Parcel',         low:71, high:80, site: 2700, ebay: 3250, tracked: true },
  {name: 'Parcel',         low:81, high:90, site: 2850, ebay: 3450, tracked: true },
  {name: 'Parcel',         low:91, high:999999, site: 3050, ebay: 3650, tracked: true },
]

export const getTotalValueFromItems = (orderItemsInput: IOrderItem[]): number => {
  let totalValue = orderItemsInput.reduce((prev, current) => {
    return prev + current.price * current.quantity;
  }, 0);
  return (totalValue);
}

export const getTotalItemsFromItems = (orderItemsInput: IOrderItem[]): number => {
  let totalItems = orderItemsInput.reduce((prev, current) => {
    return prev + current.quantity;
  }, 0);
  return totalItems;
}

export const getPaidFromTransactions = (transactionsInput: ITransaction[]): number => {
  let totalValue = transactionsInput.reduce((prev, current) => {
    return prev + current.value;
  }, 0);
  return (totalValue);
}


export const getPnpFromComicItems = (totalQuantity:number, totalValue:number): number => {
  const trackingNeeded = totalValue >= 1500;
  const postageBandNeeded = POSTAGE_BANDS.find((i:PostageBand) => (i.low <= totalQuantity && i.high >= totalQuantity && i.tracked === trackingNeeded));
  if (!postageBandNeeded) {
    console.log("WHAHAAATTTT??!");
    return 0;
  }
  return postageBandNeeded.site;
}

export const usesStandardComicPnp = (orderItem: IOrderItem) => {
  // console.log('ct = ' + ct);
  return (orderItem.itemType !== ItemType.GAME && 
          orderItem.itemType !== ItemType.ACCESSORY &&
          (!orderItem.pnp || orderItem.pnp === 0) );
}

export const calculatePnp = (order: IOrder, totalValue: number, isAdmin: boolean) => {
  if (order.shippingIsPickup) {
    return 0;
  }
  else if (isAdmin && order.name !== '') {
    return 0;
  }
  else {
    const comicItemCount = order.orderItems.reduce((total, orderItem) => (usesStandardComicPnp(orderItem) ? total + orderItem.quantity : total), 0);
    const comicsPnp = getPnpFromComicItems(comicItemCount, totalValue);

    const gamesPnp = order.orderItems.reduce((total, orderItem) => (!usesStandardComicPnp(orderItem) ? total + (orderItem.pnp * orderItem.quantity) : total), 0);

    return comicsPnp + gamesPnp;
  }
}

export const updateOrderState = (order: IOrder, transactions: ITransaction[], credit: number, isAdmin: boolean): IOrder => {
  
  const total = getTotalValueFromItems(order.orderItems);
  order.itemCount = getTotalItemsFromItems(order.orderItems);

  // console.log('transactions ' + transactions)
  const paid = getPaidFromTransactions(transactions);
  // console.log('credit ' + credit)
  // console.log('shippingIsPickup ' + order.shippingIsPickup)
  const pnp = calculatePnp(order, total, isAdmin); 
  // console.log('pnp ' + pnp)
  const toPay = (total + pnp - paid) - (credit);
  // console.log('toPay ' + toPay)
  const creditFrom = (credit);
  // console.log('creditFrom ' + creditFrom)
  const creditForward = toPay < 0 ? - toPay : 0;
  // console.log('creditForward ' + creditForward);

  order.toPay = toPay > 0 ? toPay : 0;
  order.pnp = pnp;
  order.total = total;
  order.paid = paid;
  order.creditFrom = creditFrom;
  order.creditForward = creditForward;

  return order;
}