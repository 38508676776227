import React, { useState } from 'react';
import { formatPriceComic } from '../../utils/MoneyFormats';
import { formatReleaseDateComic } from '../../utils/OrderFormats';
import './ComicPreview.scss';
import { PositionOfDiv } from './OrderListComic';
import { getAdminFromStorage } from '../../utils/UserHandler';
import EbayControls from './EbayControls';
import { getImageIdUrl } from '../../utils/ImageHandler';
import ImageViewer from './ImageViewer';
import { PeriodType } from '../../common';

type Props = {
  comicInput: IComic,
  orderItem?: IOrderItem,
  popupRef: React.RefObject<HTMLDivElement>,
  position: PositionOfDiv,
  handleSearch: (searchTerm: string) => void,
  toggleComicPreview: (event: any) => void,
  addComic: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, comic: IComic) => void
}

const coreSeries = [
  
  // Marvel
  "Avengers", "Captain America", "Carnage", "Daredevil", "Dark Web", "Darth Vader",
  "Ghost Rider", "Guardians of the Galaxy", "Hulk",
  "Iron Man", "New Mutants", "Moon Knight",
  "Scarlet Witch", "Spawn", "Spider-Gwen", "Spider-Man", "Spider-Man 2099",
  "Star Trek", "Star Wars", "Storm",
  "X-Men", "Venom", "Wolverine",

  // DC
  "Batman", "Lobo"
];

const ComicPreview: React.FC<Props> = ({ popupRef, comicInput, orderItem, position, handleSearch, toggleComicPreview, addComic }) => {

  const [comic, setComic] = useState(comicInput);
  const [imageId, setImageId] = useState(comicInput.comicId);
  const [result, setResult] = useState('');
  const comicPrice = formatPriceComic(comic, comic.batch.discountPercent);

  const formatTitle = comic.mainDescription;
  const shortTitle = formatTitle.indexOf('#') ? formatTitle.substring(0, formatTitle.indexOf('#') - 1) :
    formatTitle.indexOf('TP') ? formatTitle.substring(0, formatTitle.indexOf('TP') - 1) : '';

  const coreSearchTerms = coreSeries.filter((i) => comic.mainDescription.toLowerCase().indexOf(i.toLowerCase()) > -1 && shortTitle.toLowerCase().trim() !== i.toLowerCase().trim());
  const searchTerms = [...coreSearchTerms, shortTitle].filter((i) => i !== '');

  // console.log(coreSearchTerms);
  // console.log(searchTerms);

  const ableToPurchase = (orderItem === undefined) || (comic.number !== undefined && comic.number - orderItem.quantity > 0);
  const imgCss = comic.periodType === PeriodType.NEW ? 'comic-item-cover' : comic.periodType === PeriodType.BACK_ISSUE ? 'comic-item-cover-bi' : 'comic-item-cover-game';

  const showSelectedImage = (index: number) => {
    setImageId(index === 0 ? comic.comicId : comic.images[index - 1].imageId);
  }

  // console.log( 'comic.images.length = ' + comic.images.length );

  return (
    <>
      <div className='clear-grey-background' onClick={toggleComicPreview}>&nbsp;</div>
      <div className='comic-preview' ref={popupRef} style={{ top: position.top, left: position.left }}>
        <div className='comic-preview-image-column'>
          {comic.hasImage === undefined || comic.hasImage || comic.periodType === PeriodType.BACK_ISSUE ?

            <div className="comic-preview-holder "><img className={imgCss} src={getImageIdUrl(comic, imageId)} alt='comic preview' /></div>
            : <div className='comic-preview-cover-no-image' />}
          {comic.images.length > 0 && <ImageViewer comic={comic} showSelectedImage={showSelectedImage} />}
        </div>
        <div className='comic-preview-column'>
          <div className='comic-preview-titles'>
            <div className='comic-preview-title'>{comic.mainDescription}</div>
            <div className='comic-preview-subtitle'>{comic.variantDescription}</div>
          </div>

          <div className='comic-preview-info'>
            <div className='comic-preview-release-date'>{formatReleaseDateComic(comic)}</div>
            {(comic.publisher === 'Other' || '') && <div className='comic-preview-publisher'>{comic.publisher}</div>}
            {(comic.publisher !== 'Other') && <div className='comic-preview-publisher-link' onClick={() => handleSearch(comic.publisher === 'Marvel Comics' ? 'Marvel' : comic.publisher)}>{comic.publisher}</div>}
          </div>
          {getAdminFromStorage() ? (
            <div className='comic-preview-ebay'>
              <EbayControls comic={comic} setComic={setComic} setResult={setResult} />
              {result !== '' ? (<div>{result}</div>) : ''}
            </div>) : ''}
          <div className='comic-preview-blurb'>This comic has no description.</div>
          {searchTerms && searchTerms.length > 0 ?
            (<div className='comic-preview-search-terms'>
              <span className='comic-preview-search-term-label'>Search:</span>
              {searchTerms.map((searchTerm) => (<button key={searchTerm} onClick={() => handleSearch(searchTerm)} className='button-link comic-preview-search-term'>{searchTerm}</button>))}
            </div>) : ''
          }
          <div className='comic-preview-purchase-area' onClick={(e) => ableToPurchase && addComic(e, comic)}>
            <div className={ableToPurchase ? 'comic-preview-purchase-button' : 'comic-preview-non-purchase-button'}>
              <div className='comic-preview-item-price'>
                <span className='big-price'>${comicPrice.substring(0, comicPrice.indexOf('.'))}</span>
                <span className='little-price'>{comicPrice.substring(comicPrice.indexOf('.'))}</span>
              </div>
              {ableToPurchase ?
                (<div className='comic-preview-purchase-icon'>
                  <img className='comic-preview-purchase-icon-image' src="/img/purchase.svg" alt='purchase' />
                </div >)
                : (<div className="comic-preview-sold-out">You have the last one</div>)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ComicPreview