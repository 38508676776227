import React, { useEffect, useState } from 'react'
import { deleteBatch, getBatches } from '../../API'
import AdminNavigation from '../admin/AdminNavigation'
import { Helmet } from 'react-helmet'
import BatchItem from './BatchItem'
import Swal from 'sweetalert2'
import BatchTypeFilter from './BatchTypeFilter'

const BatchList: React.FC = () => {
  const [batches, setBatches] = useState<IBatch[]>([])
  const [batchTypeFilter, setBatchTypeFilter] = useState('all');

  useEffect(() => {
    fetchBatches();
  }, [])

  const fetchBatches = (): void => {
    // console.log(`fetchStocks()`);
    getBatches()
      .then(({ data: { batches } }: IBatch[] | any) => setBatches(batches))
      .catch((err: Error) => console.log(err))
  }

  const handleDeleteBatch = (batch: IBatch): void => {
    if (batch._id !== undefined) {

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {

          if (batch._id !== undefined) {
            deleteBatch(batch._id)
              .then(({ status }) => {
                if (status === 202) {
                  Swal.fire(
                    'NOT Deleted',
                    'Batch has NOT been deleted.',
                    'error'
                  )
                }
                else if (status === 200) {
                  fetchBatches();

                  Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                  )
                }
                else {
                  throw new Error('Error!')
                }
              })
              .catch((err) => console.log(err))
          }
        }
      })
    }
  }

  const batchesFiltered = batchTypeFilter === 'all' ? batches : [...new Set<IBatch>(batches?.filter(obj => obj.type === batchTypeFilter))];

  return (
    <>
      <Helmet>
        <title>Isle of Avalon Comics - Admin</title>
      </Helmet>
      <article>
        <h1>Batches ({batchesFiltered.length})</h1>
        <div>filter: <BatchTypeFilter batchTypeFilter={batchTypeFilter} setBatchTypeFilter={setBatchTypeFilter} /></div>
        <hr />
        {batchesFiltered && batchesFiltered.map((batch: IBatch) => (
          <BatchItem
            key={batch.name}
            batchInput={batch}
            handleDeleteBatch={handleDeleteBatch}
          />
        ))}
      </article>
      <nav><AdminNavigation /></nav>
      <aside></aside>
    </>
  )
}

export default BatchList
