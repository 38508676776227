import React from 'react'
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { dataTidy, getStatus } from '../../API';
import AdminNavigation from './AdminNavigation'

const Status: React.FC = () => {
  const [status, setStatus] = useState('');

  useEffect(() => {
    getStatus()
      .then(({ data }) => {
        // console.log(`status: ` + status);
        // console.log(`data: ` + data);
        // console.log(`status: ` + data.message);
        setStatus(data.message);
      })
      .catch((err) => console.log(err))
  }, []);

  const handleDataTidy = () => {
    dataTidy()
      .then(({ data }) => { console.log(data); Swal.fire("Success", "" + data.message, "success"); })
      .catch((err: Error) => console.log(err))
  }

  return (
    <>
      <article>
        Status: {status}
        <br /><br />
        <button className='link-button' onClick={handleDataTidy}>Data Tidy</button><br/>
      </article>
      <nav><AdminNavigation /></nav>
      <aside></aside>
    </>
  )
}

export default Status