import React from 'react'
import { getItemTypeName, getPeriodTypeName, getSupplierTypeName, OldBatchType } from '../../common';
import { Link } from 'react-router-dom';

type Props = BatchProps & {
  handleEditMode: () => void,
  handleUploadMode: () => void,
  handleDeleteBatch : (batch: IBatch) => void,
  handleClearStock : (batch: IBatch) => void,
}

const BatchItem: React.FC<Props> = ({ batch, handleEditMode, handleUploadMode, handleDeleteBatch, handleClearStock }) => {

  const isPre = batch.type === OldBatchType.PRE;

  return (
    <>
      <div>
        <div>
          <h1 className='inline no-top-margin'>{batch.name}</h1>
          <h3 className='inline'>•&nbsp;&nbsp;{batch._id}</h3>
          {batch.default && (<div>(default)<br/><br/></div>)}
          <div>Published: {batch.publishDate}</div>
          <div>{batch.cutOffDate}</div>
          <div>{getItemTypeName(batch.itemType)}&nbsp;•&nbsp;{getPeriodTypeName(batch.periodType)}&nbsp;•&nbsp;{getSupplierTypeName(batch.supplierType)}&nbsp;•&nbsp;({batch.type})</div>
          <div>Discount: {batch.discountPercent}%</div>
        </div>
        <hr/>
        <div>
          <button className="link-button" onClick={() => handleEditMode()}>edit</button>
          &nbsp;&nbsp;•&nbsp;&nbsp;<button className="link-button" onClick={() => handleDeleteBatch(batch)}>delete</button>
          {isPre && 
            <>
              &nbsp;&nbsp;•&nbsp;&nbsp;<button className="link-button" onClick={() => handleUploadMode()}>upload</button>
              &nbsp;&nbsp;•&nbsp;&nbsp;<Link className='link-button' to={`/admin-pre-order-list-condensed/${batch._id}`}>view upload</Link>
              &nbsp;&nbsp;•&nbsp;&nbsp;<button className="link-button" onClick={() => handleClearStock(batch)}>clear upload</button>
            </>}
          {/* &nbsp;&nbsp;•&nbsp;&nbsp; */}
        </div>
      </div>
    </>);
}

export default BatchItem;