import React from 'react'

type Props = {
  order:IOrder
}

const AdminSearchStockDetails: React.FC<Props> = ({ order }) => {
  
  console.log(order);

  return (
    <>
      <div>{order.user?.givenName} {order.user?.familyName}</div>
      <div>{order.user?.email}</div>
      <div>{order.shippingAddress?.addressLine1} {order.shippingAddress?.suburb} {order.shippingAddress?.state} {order.shippingAddress?.postalCode}</div>
      <div>{order.billingAddress?.addressLine1} {order.billingAddress?.suburb} {order.billingAddress?.state} {order.billingAddress?.postalCode}</div>
      <br/>
    </>
  );
}

export default AdminSearchStockDetails

