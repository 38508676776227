import React from 'react'
import { getItemTypeName, ItemType } from '../../common';

type Props = {
  batch: IBatch, 
  setBatch: (batch: IBatch) => void,
}

const BatchTypeDropDown: React.FC<Props> = ({batch, setBatch}) => {

  const itemTypes = [ItemType.COMIC, ItemType.GAME, ItemType.ACCESSORY];

  const handleChange = (e: React.FormEvent<HTMLSelectElement>): void => {
    const itemType = itemTypes?.find(itemType => itemType === e.currentTarget.value);
    if (itemType) {
      setBatch({
        ...batch,
        itemType: itemType,
      })
    }
  }

  return (
    <>
      <select className='inline auto-width' value={batch.itemType} onChange={handleChange}>
        {itemTypes && itemTypes.map((itemType, key) => <option key={key} value={itemType}>{getItemTypeName(itemType)}</option> )}
      </select>
    </>
  )
}

export default BatchTypeDropDown