import React from 'react'

type Props = AuditProps & {
}

const AuditHistoryItem: React.FC<Props> = ({ audit }) => {
  const checkOrder: string = ''; //order.status ? `line-through` : ''
  return (
    <div className='Card'>
      <div className='Card--text'>
        <span className={checkOrder}>{audit.createdAt}</span>
        <h2 className={checkOrder}>{audit.userName} ({audit.userEmail})</h2>
        <span className={checkOrder}>{audit.description}</span>
      </div>
    </div>
  )
}

export default AuditHistoryItem